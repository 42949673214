import React from 'react';
import { IonContent, IonPage, IonToast } from '@ionic/react';

//	S T Y L E

import './sb-login.scss';

//	I N T E R F A C E S

import { props___SbLoginPage, state___SbLoginPage, state___SbLoginPage___default } from './sb-login.state';

//	L I B S

import { lib_errors } from '../../libs/lib.errors.shared';
import { lib_names } from '../../libs/lib.names';

//	S E R V I C E S

import { service_RestAuthService } from '../../services/service-auth';
import { service_RestApiService } from '../../services/service-api';

//	C O M P O N E N T S

import ComCopyrightComponent from '../../components/com-copyright/com-copyright';
import ComSpinnerComponent from '../../components/com-spinners/com-spinner/com-spinner';

//	M O D A L S

import MdInfoEulaModal from '../../modals/md-info-eula/md-info-eula';
import MdSignPasswordResetModal from '../../modals/md-sign/md-sign-password-reset/md-sign-password-reset';

//	C L A S S

export default class SbLoginPage extends React.Component<props___SbLoginPage, state___SbLoginPage> {

//#region 																							D E C L A R A T I O N S

	private readonly _AUTH: service_RestAuthService = new service_RestAuthService();
	private readonly _API: service_RestApiService = new service_RestApiService();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___SbLoginPage,
	) {
		super(props);
		this.state = state___SbLoginPage___default;
	}

//#endregion

//#region 																							R E A D   P R O P S

	private read_props___me_datas = async () => {
		const ___me_props___result: any | null = await this._API.users___read___single('me', null);
		if (___me_props___result !== null) { this.props.event___setMeProps(___me_props___result); }
		else { this.props.event___setMeProps(null); }
	} 

//#endregion

//#region 																							H A N D L E R S

	private handle___password_reset = () => {
		
	}

//#endregion

//#region 																							A U T H

	private perform___login = () => {
		this.setState({
			login___is_loading: true,
		}, async () => {
			if (this.state.field___username.length > 0 || this.state.field___password.length > 0) {
				const ___auth_response: any | string | null = await this._AUTH.auth___login(this.state.field___username, this.state.field___password);
				if (___auth_response && ___auth_response['token']) {
					localStorage.setItem(lib_names.localStorage.user___logintoken, ___auth_response['token']);
					await this.read_props___me_datas().then(() => { window.location.href = window.location.href.toString(); });
				} else {
					const ___toast___login_error___text: string = (() => { switch (___auth_response) {
						case lib_errors.login.invalid_username: return 'Username non riconosciuto'; break;
						case lib_errors.login.invalid_password: return 'Password sbagliata'; break;
						default: return 'Errore generico durante, il login, se il problema persiste contatta l\'amministratore'; break;
					}})();
					this.setState({
						toast___login_error___text: ___toast___login_error___text,
						toast___login_error___visible: true,
						login___is_loading: false,
					});
				}
			}
		});
	}

//#endregion

//#region 																							R E N D E R

	render() {
		return <>
			<IonPage>

				<MdSignPasswordResetModal isOpen={ this.state.modal___password_reset___visible } event___onDidDismiss={() => { this.setState({ modal___password_reset___visible: false }); }}/>
				<MdInfoEulaModal isOpen={ this.state.modal___login_eula___visible } event___onDidDismiss={() => { this.setState({ modal___login_eula___visible: false}); }}/>

				<IonToast isOpen={ this.state.toast___login_error___visible } message={ this.state.toast___login_error___text } duration={ 3000 } color="danger" position="top" onDidDismiss={() => { this.setState({ toast___login_error___visible: false }); }}/>

				<IonContent>

					<div className="sb-login---container">
						{(this.state.login___is_loading === true) ? <>
							<section>
								<br />
								<br />
								<br />
								<br />
								<br />
								<ComSpinnerComponent size="standard"/>
							</section>
						</> : <>
							<div>
								<img src="/assets/logo-horizontal.svg"></img>
								<br/>
								<input placeholder="username" type="text" className="input---nb-outlined"  value={ this.state.field___username } onInput={(___e: any) => this.setState({ field___username: ___e.target.value })}/>
								<input placeholder="password" type="password" className="input---nb-outlined"  value={ this.state.field___password } onInput={(___e: any) => this.setState({ field___password: ___e.target.value })}/>
								<h6 onClick={() => { this.setState({ modal___password_reset___visible: true }); }}>Hai dimenticato la password?</h6>
								<br/>
								<p>
									<label htmlFor="sb-login---eula-accepted">
										<input type="checkbox" id="sb-login---eula-accepted" checked={ this.state.field___eula_accepted } onChange={(___e: any) => { this.setState({ field___eula_accepted: ___e.target.checked })}}/>
										{(this.state.field___eula_accepted === true) ? <>
											<i className="far fa-check-square"></i>
										</> : <>
											<i className="far fa-square"></i>
										</>}
									</label>
									<span>
										<>Effettuando il login nella piattaforma accetto esplicitamente ed integralmente tutti </> 
										<span onClick={() => { this.setState({ modal___login_eula___visible: true }); }}>i Termini e le Condizioni visionabili in questa pagina</span> 
									</span>
								</p>
								<br/>
								<br/>
								<div>
									{(!(this.state.field___username.length > 0 && this.state.field___password.length)) ? <>
										<button type="button" style={{cursor:'default',backgroundColor:'transparent'}}>
											<span>
												{(!(this.state.field___username.length > 0) && !(this.state.field___password.length > 0)) ? <>
													<>Mancano username e password</>
												</> : <>
													{(!(this.state.field___username.length > 0)) ? <>Manca l'username</> : <></>}
													{(!(this.state.field___password.length > 0)) ? <>Manca la password</> : <></>}
												</>}
											</span>
										</button>
									</> : <>
										{(this.state.field___eula_accepted === true) ? <>
											<button type="button" onClick={ this.perform___login } style={{color:'var(--color---light)'}}>
												<span>Login</span>
												<i className="fas fa-chevron-right"></i>
											</button>
										</> : <>
											<button type="button" style={{cursor:'default',backgroundColor:'transparent'}}>
												<span>Devi accettare i Termini</span>
											</button>
										</>}
									</> }
								</div>
							</div>
						</>}
					</div>

					<section className="sb-login---copyright">
						<ComCopyrightComponent />
					</section>

				</IonContent>

			</IonPage>
		</>;
	}

//#endregion

}