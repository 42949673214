export const locale___MdTicketViewModal = {
	'description___make_the_guy_scan': {
		de_de: "Lass diesen QR-Code von {{1}} scannen, um ihm/ihr dieses Ticket herunterzuladen",
		en_us: "Have this QR code scanned by {{1}} to allow them to download this ticket",
		es_es: "Haz que {{1}} escanee este código QR para que pueda descargar este ticket",
		fr_fr: "Faites scanner ce QR code par {{1}} pour lui permettre de télécharger ce billet",
		it_it: "Fai scansionare questo QR da {{1}} per fargli/le scaricare questo ticket"
	},
	
	/*
		'________________': {
			de_de: "________________",
			en_us: "________________",
			es_es: "________________",
			fr_fr: "________________",
			it_it: "________________"
		},
	*/

};