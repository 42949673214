import React from 'react';
import { IonCard, IonContent, IonItem, IonLabel, IonList, IonPage, IonRefresher, RefresherEventDetail, withIonLifeCycle } from '@ionic/react';
import { Link } from 'react-router-dom';

//	L O C A L E

import { locale___SbDashboardPage } from './sb-dashboard.locale';

//	S T Y L E

import './sb-dashboard.scss';

//	S T A T E   -   P R O P S

import { props___SbDiscoverFeedPage, state___SbDiscoverFeedPage, state___SbDiscoverFeedPage___default } from './sb-dashboard.state';

//	L I B S

import { lib_names } from '../../libs/lib.names';

//	T Y P E S

import { type___api___response } from '../../types/types.api-response';

//	S E R V I C E S

import { service_RestApiService } from '../../services/service-api';
import { service_LocalizationService } from '../../services/service-localization';

//	C O M P O N E N T S

import ComEventComponent from '../../components/com-events/com-event/com-event';
import ComEventNullComponent from '../../components/com-events/com-event-null/com-event-null';
import ComEventTimeScheduleComponent from '../../components/com-events/com-event-timeschedule/com-event-timeschedule';
import ComSpinnerComponent from '../../components/com-spinners/com-spinner/com-spinner';
import ComSpinnerMiniComponent from '../../components/com-spinners/com-spinner-mini/com-spinner-mini';

//	M O D A L S

import MdShareUrlModal from '../../modals/md-share-url/md-share-url';
import MdTicketEditModal from '../../modals/md-ticket/md-ticket-edit/md-ticket-edit';
import MdTicketViewModal from '../../modals/md-ticket/md-ticket-view/md-ticket-view';

//	C L A S S

class SbDashboardPage extends React.Component<props___SbDiscoverFeedPage, state___SbDiscoverFeedPage> {

//#region 																							D E C L A R A T I O N S

	private readonly _LOCALE: service_LocalizationService = new service_LocalizationService();
	private readonly _API: service_RestApiService = new service_RestApiService();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___SbDiscoverFeedPage,
	) {
		super(props);
		this.state = state___SbDiscoverFeedPage___default;
	}

//#endregion

//#region 																							R E A D   P R O P S

	private read_props___event = async () => {
		const ___stored___event_id: string | null = localStorage.getItem(lib_names.localStorage.user___event_selected);
		if (___stored___event_id !== null) {
			const ___temp___selected_event_props: type___api___response = await this._API.events___read___single(___stored___event_id);
			this.setState({
				event___props: ___temp___selected_event_props.data,
				event___props___isLoading: false
			});
		} else {
			this.setState({
				event___props___isLoading: false
			});
		}
	}

//#endregion

//#region 																							H A N D L E R S

	private handle___pageRefresh = async (___e: CustomEvent<RefresherEventDetail>) => {
		await this.read_props___event();
		___e.detail.complete();
	}

	private handle___preview___ticket_created = (___ticket_id: string) => {
		this.setState({
			modal___MdTicketEditModal___isOpen: false,
			modal___MdTicketViewModal___isOpen: (___ticket_id !== null) ? true : false,
			modal___MdTicketViewModal___argument: (___ticket_id !== null) ? ___ticket_id : null,
		});
	}

	private handle___share_url = (___url: string) => {
		this.setState({
			modal___MdShareModal___isOpen: true,
			modal___MdShareModal___argument: ___url,
		});
	}

//#endregion

//#region 																							L I F E C Y C L E

	async ionViewDidEnter() : Promise<void> {


		console.log('this.props.isLogged___props', this.props.isLogged___props)

		await this.read_props___event();
	}

//#endregion

//#region 																							R E T U R N

	render() : React.ReactNode {
		return <>
			<IonPage>

				<MdTicketEditModal isOpen={ this.state.event___props && this.state.modal___MdTicketEditModal___isOpen } ticket_id={ null } event___onDidDismiss={ this.handle___preview___ticket_created }/>
				<MdTicketViewModal isOpen={ this.state.modal___MdTicketViewModal___isOpen && this.state.modal___MdTicketViewModal___argument } ticket_id={ this.state.modal___MdTicketViewModal___argument } event___onDidDismiss={() => { this.setState({ modal___MdTicketViewModal___isOpen: false }); }}/>
				<MdShareUrlModal isOpen={ this.state.modal___MdShareModal___isOpen } event___onDidDismiss={() => { this.setState({ modal___MdShareModal___argument: null, modal___MdShareModal___isOpen: false }); }} />

				<IonContent>

					<IonRefresher slot="fixed" onIonRefresh={ this.handle___pageRefresh }>
						<ComSpinnerMiniComponent />
					</IonRefresher>

					<section className="sb-dashboard-page---container">

						<div className="sb-dashboard-page---img-header">
							<img src="/assets/logo-horizontal.svg"/>
						</div>

						<br />

						{(this.state.event___props___isLoading === true) ? <>
							<ComSpinnerComponent size="small"/>
						</> : <>
							{(this.state.event___props) ? <>
								<ComEventTimeScheduleComponent event___props={ this.state.event___props } />
								<br />
								<Link to={ '/events/view/' + this.state.event___props['event_id'] }>
									<IonCard>
										<ComEventComponent event___props={ this.state.event___props }/>
									</IonCard>
								</Link>
							</> : <>
								<Link to="/events/list">
									<ComEventNullComponent />
								</Link>
							</>}
						</>}

						<Link to="/events/list" className="sb-dashboard---event-changer">
							<i className="fas fa-exchange-alt"></i>
							<span>{ this._LOCALE.translate(locale___SbDashboardPage.button___event_change) }</span>
						</Link>

						<br />
						<br />
						<br />
						
						{/*
							<a href="https://www.nearbycommunity.it/" target="_blank">
								<ComSuggestionNearbyComponent />
							</a>
							<br />
						*/}

						{(this.state.event___props) ? <>
						
							{(this.props.isLogged___props && (this.props.isLogged___props['user_role_is___admin'] === true || this.props.isLogged___props['user_role_is___cashdesk'] === true)) ? <>
								<IonCard>
									<IonList>
										<IonItem button={ true } className="is-ion-item-button" routerLink="/cashdesk">
											<i className="fas fa-cash-register"></i>
											<IonLabel>{ this._LOCALE.translate(locale___SbDashboardPage.nav___cashdesk) }</IonLabel>
										</IonItem>
									</IonList>
								</IonCard>
								<br />
							</> : <></>}

							<IonCard>
								<IonList>
									{(this.state.event___props['event_ended'] === false) ? <>
										{(this.state.event___props['event_settings___tickets_lists'] === true) ? <>
											<IonItem className="is-ion-item-button" onClick={() => { this.setState({ modal___MdTicketEditModal___isOpen: true }); }}>
												<i className="fas fa-calendar-week"></i>
												<IonLabel><b>{ this._LOCALE.translate(locale___SbDashboardPage.nav___add___list) }</b></IonLabel>
											</IonItem>
										</> : <></>}
										{(this.state.event___props['event_settings___tickets_payment'] === true) ? <>
											<IonItem className="is-ion-item-button" onClick={() => { this.handle___share_url(lib_names.endpoints.ticket_buy + this.state.event___props['event_id'] + '/' + this.props.isLogged___props['user_id']); }}>
												<i className="fas fa-external-link-alt"></i>
												<IonLabel><b>{ this._LOCALE.translate(locale___SbDashboardPage.nav___ticket) }</b></IonLabel>
											</IonItem>
										</> : <></>}
										<IonItem button={ true } className="is-ion-item-button" routerLink="/tickets/list">
											<i className="fas fa-list"></i>
											<IonLabel>{ this._LOCALE.translate(locale___SbDashboardPage.nav___ticket_list) }</IonLabel>
										</IonItem>
									</> : <></>}
								</IonList>
							</IonCard>

							<br />

							<IonCard>
								<IonList>
									{(this.props.isLogged___props && this.props.isLogged___props['user_role_is___admin'] === true && this.state.event___props) ? <>
										{(this.state.event___props['event_settings___tickets_lists'] === true) ? <>
											<IonItem button={ true } className="is-ion-item-button" routerLink="/tickets/types">
												<i className="fas fa-ticket"></i>
												<IonLabel>{ this._LOCALE.translate(locale___SbDashboardPage.nav___ticket_settings) }</IonLabel>
											</IonItem>
										</> : <></>}
									</> : <></>}

								{/*
									{(this.props.isLogged___props && this.props.isLogged___props['user_role_is___admin'] === true && this.state.event___props) ? <>
										<IonItem button={ true } className="is-ion-item-button" routerLink={ '/analytics/global/' + this.state.event___props['event_id'] }>
											<i className="fas fa-chart-line"></i>
											<IonLabel>{ this._LOCALE.translate(locale___SbDashboardPage.nav___stats___event) }</IonLabel>
										</IonItem>
									</> : <></>}
									<IonItem button={ true } className="is-ion-item-button" routerLink={ '/analytics/me/' + this.state.event___props['event_id'] }>
										<i className="fas fa-chart-line"></i>
										<IonLabel>{ this._LOCALE.translate(locale___SbDashboardPage.nav___stats___personal) }</IonLabel>
									</IonItem>
								*/}

								</IonList>
							</IonCard>
							
							<br />

						</> : <></>}

						<IonCard>
							<IonList>
								<IonItem button={ true } className="is-ion-item-button" routerLink="/events/list">
									<i className="fas fa-calendar-alt"></i>
									<IonLabel>{ this._LOCALE.translate(locale___SbDashboardPage.nav___event_list) }</IonLabel>
								</IonItem>
								{(this.props.isLogged___props && this.props.isLogged___props['user_role_is___admin'] === true) ? <>
									<IonItem button={ true } className="is-ion-item-button" routerLink="/users/list">
										<i className="fas fa-users"></i>
										<IonLabel>{ this._LOCALE.translate(locale___SbDashboardPage.nav___staff) }</IonLabel>
									</IonItem>
								</> : <></>}
							</IonList>
						</IonCard>

						<br />

						<IonCard>
							<IonList>
								<IonItem button={ true } className="is-ion-item-button" routerLink="/settings">
									<i className="fas fa-cog"></i>
									<IonLabel>{ this._LOCALE.translate(locale___SbDashboardPage.nav___settings) }</IonLabel>
								</IonItem>
							</IonList>
						</IonCard>

					</section>

					<br />
					<br />
					<br />

				</IonContent>

			</IonPage>
		</>;
	}

//#endregion

}

//	E X P O R T

export default withIonLifeCycle(SbDashboardPage);