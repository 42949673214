import React from 'react'

//	S T Y L E

import './com-event-selected.scss'

//	C L A S S

export default class ComEventSelectedComponent extends React.Component<{}, {}> {

//#region 																							R E N D E R

	render() : React.ReactNode {
		return <>
			<section className="com-event-selected---container">
				<h4>Evento Selezionato</h4>
			</section>
		</>;
	}

//#endregion

}