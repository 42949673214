//	L I B S

import { lib_names } from '../libs/lib.names';

//	T Y P E S

import { type___api___response } from '../types/types.api-response';
import { type___organization_setting___property, type___payment___type, type___user_setting___property } from '../types/types.types';

//	E X P O R T

export class service_RestApiService {

//#region 																							D E C L A R A T I O N S

	private _HEADERS: any = { 'Content-Type': 'application/json' };

//#endregion

//#region 																							W H O A M I

	private load_props___whoami() {
		const ___whoami_token: string | null = <string>localStorage.getItem(lib_names.localStorage.user___logintoken) ?? null;
		if (___whoami_token) { this._HEADERS['Authorization'] = `Bearer ${___whoami_token}`; }
	}

//#endregion

//#region 																							R E T U R N   S E R V E R   R E S P O N S E

	private async return_server_response(___action: string, ___target: string, ___to_post: any | {} = {}) : Promise<type___api___response|any|any[]>
	{
		this.load_props___whoami();





	//	console.log('this._HEADERS',this._HEADERS)






		const ___s_route: string = lib_names.endpoints.server_api + ___action + '/' + ___target;
		try {
			const ___s_response: Response = await fetch(___s_route, { method: 'POST', headers: this._HEADERS, body: JSON.stringify(___to_post) });
			if (___s_response.ok) {
				try {
					const ___server_response: any = await ___s_response.json();
					return (___server_response !== null && ___server_response !== undefined) ? ___server_response : null;
				} catch (___e: any) {
					return { response: 'error', message: 'api-error', data: {} };
				}
			} else {
				return { response: 'error', message: 'api-error', data: {} };
			}
		} catch (___e_main: any) {
			console.error('Error fetching server response:', ___e_main);
			return { response: 'error', message: 'api-error', data: {} };
		}
	}

//#endregion

//#region 																							A U T H   P A S S W O R D   C H A N G E

	public async auth___perform___password_change(___old_password: string | null, ___new_password: string) : Promise<type___api___response>
	{
		const ___to_post: any = { 'old_password': ___old_password, 'new_password': ___new_password };
		return await this.return_server_response('auth', 'password_change', ___to_post);
	}

//#endregion

//#region 																							A N A L Y T I C S

	public async analytics___read___event_graph(___event_id: string) : Promise<type___api___response>
	{
		const ___to_post: any = { 'event_id': ___event_id };
		return await this.return_server_response('analytics', 'event_graph', ___to_post);
	}

	public async analytics___read___users(___event_id: string, ___order: string | null, ___only_for_me: boolean = true) : Promise<type___api___response>
	{
		const ___to_post: any = { 'event_id': ___event_id, 'order': ___order, 'only_for_me': ___only_for_me };
		return await this.return_server_response('analytics', 'users', ___to_post);
	}

//#endregion

//#region 																							C A S H D E S K

	public async cashdesk___tickets___list(___event___id: string) : Promise<type___api___response>
	{
		const ___to_post: any = { 'event_id': ___event___id };
		return await this.return_server_response('cashdesk', 'tickets_list', ___to_post);
	}

	public async cashdesk___ticket___preload(___event___id: string, ___code: string) : Promise<type___api___response>
	{
		const ___to_post: any = { 'event_id': ___event___id, 'code': ___code };
		return await this.return_server_response('cashdesk', 'ticket_preload', ___to_post);
	}

	public async cashdesk___ticket___validate_and_use(___event___id: string, ___code: string) : Promise<type___api___response>
	{
		const ___to_post: any = { 'event_id': ___event___id, 'code': ___code };
		return await this.return_server_response('cashdesk', 'validate_and_use', ___to_post);
	}

//#endregion

//#region 																							E V E N T S

	public async events___connect(___event_id: string, ___auth_code: string, ___ticket_list_enable: boolean, ___ticket_payment_enable: boolean, ___ticket_payment_enable_datetime_start: string | null, ___ticket_payment_enable_datetime_end: string | null, ___ticket_quantity_max: number | null) : Promise<type___api___response>
	{
		const ___to_post: any = { 'event_id': ___event_id, 'auth_code': ___auth_code, 'ticket_list_enable': ___ticket_list_enable, 'ticket_payment_enable': ___ticket_payment_enable, 'ticket_payment_enable_datetime_start': ___ticket_payment_enable_datetime_start, 'ticket_payment_enable_datetime_end': ___ticket_payment_enable_datetime_end, 'ticket_quantity_max': ___ticket_quantity_max };
		return await this.return_server_response('events', 'connect', ___to_post);
	}

	public async events___read___multi(___search_target: string, ___search_param: string | null, ___last_index: number = 0) : Promise<type___api___response>
	{
		const ___to_post: any = { 'search_target': ___search_target, 'search_param': ___search_param, 'last_index': ___last_index };
		return await this.return_server_response('events', 'read_multi', ___to_post);
	}

	public async events___read___single(___event_id: string) : Promise<type___api___response>
	{
		const ___to_post: any = { 'event_id': ___event_id };
		return await this.return_server_response('events', 'read_single', ___to_post);
	}

	public async events___read___single___preview(___event_id: string) : Promise<type___api___response>
	{
		const ___to_post: any = { 'event_id': ___event_id };
		return await this.return_server_response('events', 'read_single_preview', ___to_post);
	}

//#endregion

//#region 																							O R G A N I Z A T I O N S

	public async organizations___read___single() : Promise<any>
	{
		const ___to_post: any = { };
		return await this.return_server_response('organizations', 'read_single', ___to_post);
	}

	public async organizations___change_settings___organizations(___settings_collection: type___organization_setting___property[]) : Promise<type___api___response>
	{
		const ___to_post: any = { 'settings_collection': ___settings_collection };
		return await this.return_server_response('organizations', 'change_settings', ___to_post);
	}

//#endregion

//#region 																							P A Y M E N T S   U S E R S

	public async payments_users___read___multi(___order_by: string = 'standard') : Promise<any>
	{
		const ___to_post: any = { 'order': ___order_by };
		return await this.return_server_response('payments_users', 'read_multi', ___to_post);
	}

	public async payments_users___read___single(___user_id: string, ___event_id: string | null = null) : Promise<any>
	{
		const ___to_post: any = { 'user_id': ___user_id, 'event_id': ___event_id };
		return await this.return_server_response('payments_users', 'read_single', ___to_post);
	}

	public async payments_users___read___list(___user_id: string) : Promise<any>
	{
		const ___to_post: any = { 'user_id': ___user_id };
		return await this.return_server_response('payments_users', 'read_list', ___to_post);
	}

	public async payments_users___submit(___payment_type: type___payment___type, ___payment_to: string, ___payment_event: string, ___payment_quantity: number, ___payment_unit_price: number, ___payment_notes: string) : Promise<any>
	{
		const ___to_post: any = { 'payment_type': ___payment_type, 'payment_to': ___payment_to, 'payment_event': ___payment_event, 'payment_quantity': ___payment_quantity, 'payment_unit_price': ___payment_unit_price, 'payment_notes': ___payment_notes};
		return await this.return_server_response('payments_users', 'submit', ___to_post);
	}

//#endregion

//#region 																							T I C K E T S

	public async tickets___read___multi(___event_id: string, ___search_target: string, ___search_param: string | null, ___last_index: number = 0, ___order: string) : Promise<type___api___response>
	{
		const ___to_post: any = { 'event_id': ___event_id, 'search_target': ___search_target, 'search_param': ___search_param, 'last_index': ___last_index, 'order': ___order };
		return await this.return_server_response('tickets', 'read_multi', ___to_post);
	}

	public async tickets___read___single(___t_id: string) : Promise<type___api___response>
	{
		const ___to_post: any = { 'ticket_id': ___t_id, };
		return await this.return_server_response('tickets', 'read_single', ___to_post);
	}

	public async tickets___submit(___t_id: string | null, ___t_event: string, ___t_persona: string, ___t_persona_email: string, ___t_notes: string) : Promise<type___api___response>
	{
		const ___to_post: any = { 'ticket_id': ___t_id, 'ticket_event': ___t_event, 'ticket_persona': ___t_persona, 'ticket_persona_email': ___t_persona_email, 'ticket_notes': ___t_notes };
		return await this.return_server_response('tickets', 'submit', ___to_post);
	}

//#endregion

//#region 																							T I C K E T S   T Y P E S

	public async tickets_types___read___multi(___event_id: string, ___last_index: number = 0) : Promise<type___api___response>
	{
		const ___to_post: any = { 'event_id': ___event_id, 'last_index': ___last_index };
		return await this.return_server_response('tickets_types', 'read_multi', ___to_post);
	}

	public async tickets_types___read___single(___tt_id: string) : Promise<type___api___response>
	{
		const ___to_post: any = { 'ticket_type_id': ___tt_id };
		return await this.return_server_response('tickets_types', 'read_single', ___to_post);
	}	

	public async tickets_types___submit(___tt___id: string, ___tt___event: string, ___tt___name: string, ___tt___description: string, ___tt___price: number, ___tt___quantity: number | null) : Promise<type___api___response>
	{
		const ___to_post: any = { 'tt_id': ___tt___id, 'tt_event': ___tt___event, 'tt_name': ___tt___name, 'tt_description': ___tt___description, 'tt_price': ___tt___price, 'tt_quantity': ___tt___quantity };
		return await this.return_server_response('tickets_types', 'submit', ___to_post);
	}

//#endregion

//#region 																							U S E R S

	public async users___read___multi(___search_target: string, ___search_param: string | number | null) : Promise<type___api___response>
	{
		const ___to_post: any = { 'search_target': ___search_target, 'search_param': ___search_param };
		return await this.return_server_response('users', 'read_multi', ___to_post);
	}

	public async users___read___single(___search_target: 'id' | 'me', ___search_param: string | null = null) : Promise<type___api___response>
	{
		const ___to_post: any = { 'search_target': ___search_target, 'search_param': ___search_param };
		return await this.return_server_response('users', 'read_single', ___to_post);
	}

	public async users___submit(___user_id: null | 'me', ___user_username: string, ___user_name_displayed: string, ___user_name: string, ___user_surname: string, ___user_contact_mail: string, ___user_contact_phone: string, ___user_role_is_admin: boolean, ___user_role_is_cashdesk: boolean) : Promise<type___api___response>
	{
		const ___to_post: any = { 'user_id': ___user_id, 'user_username': ___user_username, 'user_name_displayed': ___user_name_displayed, 'user_name': ___user_name, 'user_surname': ___user_surname, 'user_contact_mail': ___user_contact_mail, 'user_contact_phone': ___user_contact_phone, 'user_role_is_admin': ___user_role_is_admin, 'user_role_is_cashdesk': ___user_role_is_cashdesk };
		return await this.return_server_response('users', 'submit', ___to_post);
	}

	public async users___change_settings(___user_id: 'me' | string, ___settings_collection: type___user_setting___property[]) : Promise<type___api___response>
	{
		const ___to_post: any = { 'user_id': ___user_id, 'settings_collection': ___settings_collection };
		return await this.return_server_response('users', 'change_settings', ___to_post);
	}

//#endregion





















































































































}