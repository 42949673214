import { interface___modal } from "../../../interfaces/interface.modals";

//	P R O P S

export interface props___MdSignPasswordResetModal extends interface___modal {

}

//	S T A T E

export interface state___MdSignPasswordResetModal {

	password_reset___field___user_email: string,
	password_reset___is_loading: boolean,

	toast___password_reset_success___visible: boolean,
	toast___password_reset_error___visible: boolean,

}

//	S T A T E   D E F A U L T

export const state___MdSignPasswordResetModal___default: state___MdSignPasswordResetModal = {

	password_reset___field___user_email: '',
	password_reset___is_loading: false,

	toast___password_reset_success___visible: false,
	toast___password_reset_error___visible: false,

}