export const locale___SbTicketsListPage = {
	'alert___delete_ticket___title': {
		de_de: "Warte",
		en_us: "Wait",
		es_es: "Espera",
		fr_fr: "Attends",
		it_it: "Aspetta"
	},
	'alert___delete_ticket___message': {
		de_de: "Möchtest du dieses Ticket wirklich löschen?",
		en_us: "Do you really want to delete this ticket?",
		es_es: "¿Realmente quieres eliminar este ticket?",
		fr_fr: "Voulez-vous vraiment supprimer ce billet ?",
		it_it: "Vuoi davvero eliminare il ticket?"
	},
	'alert___delete_ticket___button___yes': {
		de_de: "Ja",
		en_us: "Yes",
		es_es: "Sí",
		fr_fr: "Oui",
		it_it: "Si"
	},
	'alert___delete_ticket___button___cancel': {
		de_de: "Abbrechen",
		en_us: "Cancel",
		es_es: "Cancelar",
		fr_fr: "Annuler",
		it_it: "Annulla"
	},
	'actionSheet___show': {
		de_de: "Anzeigen",
		en_us: "View",
		es_es: "Ver",
		fr_fr: "Voir",
		it_it: "Visualizza"
	},
	'actionSheet___send_email': {
		de_de: "E-Mail erneut senden",
		en_us: "Resend Email",
		es_es: "Reenviar Correo Electrónico",
		fr_fr: "Renvoyer l'Email",
		it_it: "Reinvia Email"
	},
	'actionSheet___delete': {
		de_de: "Löschen",
		en_us: "Delete",
		es_es: "Eliminar",
		fr_fr: "Supprimer",
		it_it: "Elimina"
	},
	'actionSheet___cancel': {
		de_de: "Abbrechen",
		en_us: "Cancel",
		es_es: "Cancelar",
		fr_fr: "Annuler",
		it_it: "Annulla"
	},
	'title___event_selected': {
		de_de: "Ausgewähltes Event",
		en_us: "Selected Event",
		es_es: "Evento Seleccionado",
		fr_fr: "Événement Sélectionné",
		it_it: "Evento Selezionato"
	},
	'button___stats': {
		de_de: "Statistiken",
		en_us: "Statistics",
		es_es: "Estadísticas",
		fr_fr: "Statistiques",
		it_it: "Statistiche"
	},
	'button___stats___personal': {
		de_de: "Persönliche Statistiken",
		en_us: "Personal Statistics",
		es_es: "Estadísticas Personales",
		fr_fr: "Statistiques Personnelles",
		it_it: "Statistiche Personali"
	},
	'title___ticket_list': {
		de_de: "Ticket-Liste",
		en_us: "Ticket List",
		es_es: "Lista de Tickets",
		fr_fr: "Liste des Billets",
		it_it: "Lista Tickets"
	},
	'ticket_view___label': {
		de_de: "Anzeigen",
		en_us: "View",
		es_es: "Visualizar",
		fr_fr: "Voir",
		it_it: "Visualizza"
	},
	'ticket_view___all': {
		de_de: "Alle Tickets",
		en_us: "All Tickets",
		es_es: "Todos los Tickets",
		fr_fr: "Tous les Billets",
		it_it: "Tutti i Ticket"
	},
	'ticket_view___used': {
		de_de: "Alle Tickets (Verwendet)",
		en_us: "All Tickets (Used)",
		es_es: "Todos los Tickets (Usados)",
		fr_fr: "Tous les Billets (Utilisés)",
		it_it: "Tutti i Ticket (Usati)"
	},
	'ticket_view___only_mine': {
		de_de: "Meine Tickets",
		en_us: "My Tickets",
		es_es: "Mis Tickets",
		fr_fr: "Mes Billets",
		it_it: "Miei Ticket"
	},
	'ticket_view___only_mine___used': {
		de_de: "Meine Tickets (Verwendet)",
		en_us: "My Tickets (Used)",
		es_es: "Mis Tickets (Usados)",
		fr_fr: "Mes Billets (Utilisés)",
		it_it: "Miei Ticket (Usati)"
	},
	'ticket_sort___label': {
		de_de: "Sortieren nach",
		en_us: "Sort by",
		es_es: "Ordenar por",
		fr_fr: "Trier par",
		it_it: "Ordina per"
	},
	'ticket_sort___standards': {
		de_de: "Standard",
		en_us: "Standard",
		es_es: "Estándar",
		fr_fr: "Standard",
		it_it: "Standard"
	},
	'ticket_sort___name_az': {
		de_de: "Name (A-Z)",
		en_us: "Name (A-Z)",
		es_es: "Nombre (A-Z)",
		fr_fr: "Nom (A-Z)",
		it_it: "Nome (A-Z)"
	},
	'ticket_sort___name_za': {
		de_de: "Name (Z-A)",
		en_us: "Name (Z-A)",
		es_es: "Nombre (Z-A)",
		fr_fr: "Nom (Z-A)",
		it_it: "Nome (Z-A)"
	},
	'ticket_sort___added_new': {
		de_de: "Hinzugefügt (Neu)",
		en_us: "Date Added (Newest)",
		es_es: "Fecha de Adición (Reciente)",
		fr_fr: "Date d'ajout (Récent)",
		it_it: "Data di Aggiunta (Su)"
	},
	'ticket_sort___added_old': {
		de_de: "Hinzugefügt (Alt)",
		en_us: "Date Added (Oldest)",
		es_es: "Fecha de Adición (Antigua)",
		fr_fr: "Date d'ajout (Ancienne)",
		it_it: "Data di Aggiunta (Giu)"
	}



	/*
		'____________________________': {
			de_de: '____________________________',
			en_us: '____________________________',
			es_es: '____________________________',
			fr_fr: '____________________________',
			it_it: '____________________________',
		},
	*/

};