import React from 'react';
import { IonBackButton, IonButtons, IonCard, IonContent, IonHeader, IonItem, IonLabel, IonList, IonPage, IonRefresher, IonToolbar, RefresherEventDetail, withIonLifeCycle } from '@ionic/react';
import { IonInfiniteScrollCustomEvent } from '@ionic/core';

//	L O C A L E

import { locale___SbTicketsTypesPage } from './sb-tickets-types.locale';

//	S T Y L E

import './sb-tickets-types.scss';

//	S T A T E   -   P R O P S

import { props___SbTicketsTypesPage, state___SbTicketsTypesPage, state___SbTicketsTypesPage___default } from './sb-tickets-types.state';

//	L I B S

import { lib_names } from '../../../libs/lib.names';

//	T Y P E S

import { type___api___response } from '../../../types/types.api-response';

//	S E R V I C E S

import { service_LocalizationService } from '../../../services/service-localization';
import { service_RestApiService } from '../../../services/service-api';

//	C O M P O N E N T S

import ComEventComponent from '../../../components/com-events/com-event/com-event';
import ComListEmptyComponent from '../../../components/com-list-empty/com-list-empty';
import ComSpinnerMiniComponent from '../../../components/com-spinners/com-spinner-mini/com-spinner-mini';
import ComTicketTypeComponent from '../../../components/com-tickets/com-ticket-type/com-ticket-type';

//	M O D A L S

import MdTicketViewModal from '../../../modals/md-ticket/md-ticket-view/md-ticket-view';
import MdTicketTypeEditModal from '../../../modals/md-ticket/md-ticket-type-edit/md-ticket-type-edit';

//	C L A S S

class SbTicketsTypesPage extends React.Component<props___SbTicketsTypesPage, state___SbTicketsTypesPage> {

//#region 																							D E C L A R A T I O N S

	private readonly _LOCALE: service_LocalizationService = new service_LocalizationService();
	private readonly _API: service_RestApiService = new service_RestApiService();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___SbTicketsTypesPage,
	) {
		super(props);
		this.state = state___SbTicketsTypesPage___default;
	}

//#endregion

//#region 																							R E A D   P R O P S

	private read_props___event = async () => {
		const ___temp___event___props: type___api___response = await this._API.events___read___single(this.state.event___id);
		if (!___temp___event___props || ___temp___event___props.response !== 'success' || ___temp___event___props.data === null) { console.log('404'); }
		this.setState({
			event___props: ___temp___event___props.data,
			event___props___isLoading: false,
		}, async () => {
			await this.read_props___tickets___collection();
		});
	}

	private read_props___tickets___collection = async () => {
		const ___temp___tickets_collection: type___api___response = await this._API.tickets_types___read___multi(this.state.event___id, this.state.tickets_types___collection.length);
		if (!___temp___tickets_collection || ___temp___tickets_collection.response === 'error' || ___temp___tickets_collection.data === null) { console.log('404'); }
		this.setState((prevState) => ({
			tickets_types___collection: [...prevState.tickets_types___collection, ...___temp___tickets_collection.data],
			tickets_types___collection___isLoading: (___temp___tickets_collection.response === 'success' || ___temp___tickets_collection.response === 'no-data') ? false : true,
		}));
	}

//#endregion

//#region 																							H A N D L E R S

	private handle___page___refresh = (___e: CustomEvent<RefresherEventDetail>) => {
		this.read_props___event();
		___e.detail.complete();
	}

	private handle___page___load_more = (___e: IonInfiniteScrollCustomEvent<void> | null) => {
		this.setState({
			tickets_types___collection___isLoading: true,
		}, async () => {
			await this.read_props___tickets___collection();
			if (___e !== null) { ___e.target.complete(); }
		})
	}

	private handle___ticket___onClick = (___ticket_props: any) => {
		this.setState({
			modal___MdTicketViewModal___argument: ___ticket_props['ticket_id'],
		//	actionSheet___ticket_selected___visibile: true
		});
	}

//#endregion

//#region 																							L I F E C Y C L E

	ionViewWillEnter() : void {
		this.setState({
			event___id: localStorage.getItem(lib_names.localStorage.user___event_selected)!,
		}, async () => {
			await this.read_props___event();
		});
	}

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode {
		return <>
			
			{/*
				<IonAlert isOpen={ this.state.alert___delete_ticket___visibility } { ...this.SbTicketsTypesPage___IonAlert___logout_check } />
				<IonActionSheet isOpen={ this.state.actionSheet___ticket_selected___visibile } { ...(this.element___IonActionSheet___ticket_options()) } onDidDismiss={() => { this.setState({ actionSheet___ticket_selected___visibile: false }); }} />
			*/}

			<MdTicketViewModal isOpen={ this.state.modal___MdTicketViewModal___isOpen } ticket_id={ this.state.modal___MdTicketViewModal___argument } event___onDidDismiss={() => { this.setState({ modal___MdTicketViewModal___isOpen: false, modal___MdTicketViewModal___argument: null }); }} />
			<MdTicketTypeEditModal isOpen={ this.state.modal___MdTicketTypeEditModal___isOpen } ticket_id={ null } event___onDidDismiss={() => { this.setState({ modal___MdTicketTypeEditModal___isOpen: false }); }} />

			<IonPage>

				<IonHeader className="ion-no-border" collapse="fade">
					<IonToolbar>
						<IonButtons slot="start">
							<IonBackButton defaultHref="/dashboard"></IonBackButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>

				<IonContent>

					<IonRefresher slot="fixed" onIonRefresh={ this.handle___page___refresh }>
						<ComSpinnerMiniComponent />
					</IonRefresher>

					<section className="sb-tickets-list---header">
						<h3>{ this._LOCALE.translate(locale___SbTicketsTypesPage.title___event) }</h3>
						<br />
						{(this.state.event___props___isLoading === false && this.state.event___props !== null) ? <>
							<IonCard>
								<ComEventComponent event___props={ this.state.event___props }/>
							</IonCard>
						</> : <>
							<ComSpinnerMiniComponent />
						</>}
						<br />
						<h3>{ this._LOCALE.translate(locale___SbTicketsTypesPage.title___tickets_types) }</h3>
						<br />
						<IonCard>
							<IonList>
								<IonItem button={ true } className="is-ion-item-button" onClick={() => { this.setState({ modal___MdTicketTypeEditModal___isOpen: true }); }}>
									<i className="fas fa-plus"></i>
									<IonLabel>{ this._LOCALE.translate(locale___SbTicketsTypesPage.button___add_ticket) }</IonLabel>
								</IonItem>
							</IonList>
						</IonCard>
					</section>
						
					<section style={{padding:'10pt'}}>
						{this.state.tickets_types___collection.map((___t: any, ___t_idx: number) => { return <>
							<section key={ ___t_idx }>
								<ComTicketTypeComponent ticket_type___props={ ___t } />
							</section>
							<br />
						</>;})}
					</section>

					{(this.state.tickets_types___collection___isLoading === false) ? <>
						<section onClick={() => { this.handle___page___load_more(null); }}>
							<ComListEmptyComponent text="Carica altro..."/>
						</section>
					</> : <>
						<ComSpinnerMiniComponent />
						{/*<ComListEmptyComponent text={ this.state.tickets___collection.length > 0 ? 'Nient altro da caricare...' : 'Niente da visualizzare...' }} /> */}
					</>}

				</IonContent>

			</IonPage>

		</>;
	}

//#endregion

}

//	E X P O R T

export default withIonLifeCycle(SbTicketsTypesPage);