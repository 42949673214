import { interface___modal } from "../../../interfaces/interface.modals";

//	P R O P S

export interface props___MdTicketTypeEditModal extends interface___modal {

	event_id?: string | null,
//	ticket_id: string | null

}

//	S T A T E

export interface state___MdTicketTypeEditModal {

	alert___askForClose: boolean,

	me___props: any | null,
	me___props___is_loading: boolean,

	ticket_type___event_id: string | null,
	ticket_type___event_props: any | null,
	ticket_type___event_props___isLoading: boolean,

	ticket_type___name: string,
	ticket_type___description: string,
	ticket_type___price: number,
	ticket_type___max_quantity: number,

	ticket_type___props___isLoading: boolean,

}

//	S T A T E   D E F A U L T

export const state___MdTicketTypeEditModal___default: state___MdTicketTypeEditModal = {

	alert___askForClose: false,

	me___props: null,
	me___props___is_loading: true,

	ticket_type___event_id: null,
	ticket_type___event_props: null,
	ticket_type___event_props___isLoading: true,

	ticket_type___name: '',
	ticket_type___description: '',
	ticket_type___price: 0,
	ticket_type___max_quantity: 0,

	ticket_type___props___isLoading: true,

}