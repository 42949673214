import React, { createRef } from 'react';
import { IonButton, IonButtons, IonContent, IonFooter, IonHeader, IonModal, IonSkeletonText, IonToolbar, withIonLifeCycle } from '@ionic/react';

//	L O C A L E

import { locale___MdTicketViewModal } from './md-ticket-view.locale';

//	S T Y L E

import './md-ticket-view.scss';

//	L I B S

import { lib_names_shared } from '../../../libs/lib.names.shared';

//	S T A T E   -   P R O P S

import { props___MdTicketViewModal, state___MdTicketViewModal, state___MdTicketViewModal___default } from './md-ticket-view.state';

//	F U N C S

import { funcs_datetime } from '../../../funcs/funcs.datetime';

//	T Y P E S

import { type___api___response } from '../../../types/types.api-response';

//	S E R V I C E S

import { service_RestApiService } from '../../../services/service-api';
import { service_LocalizationService } from '../../../services/service-localization';

//	C O M P O N E N T S

import ComButtonTicketShareComponent from '../../../components/com-button-ticket-share/com-button-ticket-share';
import ComQrViewComponent from '../../../components/com-qr-view/com-qr-view';
import ComSpinnerComponent from '../../../components/com-spinners/com-spinner/com-spinner';
import ComTicketTypeComponent from '../../../components/com-tickets/com-ticket-type/com-ticket-type';
import ComSpinnerMiniComponent from '../../../components/com-spinners/com-spinner-mini/com-spinner-mini';

//	C L A S S

class MdTicketViewModal extends React.Component<props___MdTicketViewModal, state___MdTicketViewModal> {

//#region 																							C O N F I G

	private readonly MdTicketViewModal___modal_config: any = {
		breakpoints: [0, 1],
		backdropDismiss: true,
		handle: true,
		initialBreakpoint: 1,
		keyboardClose: true,
		showBackdrop: true,
		onDidPresent: () => { this.event___onDidPresent(); },
		onDidDismiss: () => { this.event___onDidDismiss(); }
	};

//#endregion

//#region 																							D E C L A R A T I O N S

	private readonly _LOCALE: service_LocalizationService = new service_LocalizationService();
	private readonly _API: service_RestApiService = new service_RestApiService();

	private readonly funcs___datetime: funcs_datetime = new funcs_datetime();

	private MdTicketViewModal___ticket_dom_reference: React.RefObject<HTMLDivElement> = createRef<HTMLDivElement>();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___MdTicketViewModal,
	) {
		super(props);
		this.state = state___MdTicketViewModal___default;
	}

//#endregion

//#region 																							R E A D   P R O P S

	private read_props___ticket = async () => {
		const ___temp___ticket_props: type___api___response = await this._API.tickets___read___single(this.props.ticket_id!);
		console.log('___temp___ticket_props', ___temp___ticket_props)
		if (___temp___ticket_props.response === 'success') {
			this.setState({
				ticket___props: ___temp___ticket_props['data'],
				ticket___props___isLoading: false,
			}, async () => {
				const ___temp___event___props: type___api___response = await this._API.events___read___single(this.state.ticket___props['ticket_event']);
				if (!___temp___event___props || ___temp___event___props.response !== 'success' || ___temp___event___props.data === null) { console.log('404'); }
				this.setState({
					event___props: ___temp___event___props.data,
					event___props___isLoading: false,
				});
			});
		} else {
			this.props.event___onDidDismiss();
		}
	}

//#endregion

//#region 																							L I F E C Y C L E

	private event___onDidPresent() : void {
		this.setState({
			ticket___id: this.props.ticket_id!
		}, async () => {
			await this.read_props___ticket();
		});
	}

	private event___onDidDismiss() : void {
		this.setState({
			...state___MdTicketViewModal___default
		}, () => {
			this.props.event___onDidDismiss();
		});
	}

//#endregion

//#region 																							R E T U R N

	render() : React.ReactNode {
		return <>
			<IonModal isOpen={ this.props.isOpen } { ...this.MdTicketViewModal___modal_config }>

				<IonHeader className="ion-no-border" collapse="fade">
					<IonToolbar>
						<IonButtons slot="end">
							<IonButton onClick={ this.props.event___onDidDismiss }>chiudi</IonButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>

				<IonContent>
					<section ref={ this.MdTicketViewModal___ticket_dom_reference } className="md-ticket-view---container">
						{(this.state.ticket___props___isLoading === true) ? <>
							<br />
							<br />
							<br />
							<ComSpinnerComponent size="small"/>
						</> : <>
							<br />
							<section className="md-ticket-view---container---writed">
								<h5>{this._LOCALE.translate(locale___MdTicketViewModal.description___make_the_guy_scan,[this.state.ticket___props['ticket_person_name']])}</h5>
							</section>
							<ComQrViewComponent qrCode_content={ lib_names_shared.endpoints.qr + this.state.ticket___props['ticket_code'] }/>
							<section className="md-ticket-view---container---writed">
								<h2>{ this.state.ticket___props['ticket_person_name'] }</h2>
								<h4>
									{(this.state.event___props___isLoading === true) ? <>
										<IonSkeletonText animated={ true } style={{width:'50vw'}}></IonSkeletonText>
									</> : <>
										<span>{ this.state.event___props['event_name'] }</span>
									</>}
								</h4>
								<br />
								{(this.state.ticket___props['ticket_type'] !== null) ? <>
									{(this.state.ticket_type___props___isLoading === true) ? <>
										<ComSpinnerMiniComponent />
									</> : <>
										<ComTicketTypeComponent ticket_type___props={ this.state.ticket_type___props } show___description={ true }/>
									</>}
								</> : <></>}
								<br />
								<p>{ this.state.ticket___props['ticket_notes'] }</p>
							</section>
							<br />
							<br />
						</>}
					</section>
				</IonContent>

				<IonFooter>
					{(this.state.ticket___props___isLoading === false) ? <>
						<section className="container---footer-section">
							<ComButtonTicketShareComponent ticket_type='standard' ticket_code={ this.state.ticket___props['ticket_code'] } ticket_event_props={ this.state.event___props } ticket_dom_reference={ this.MdTicketViewModal___ticket_dom_reference }/>
						</section>
					</> : <></>}
				</IonFooter>

			</IonModal>
		</>;
	}

//#endregion

}

//	E X P O R T

export default withIonLifeCycle(MdTicketViewModal);