import React from 'react';
import { IonAlert, IonBackButton, IonButtons, IonCard, IonContent, IonFooter, IonHeader, IonItem, IonLabel, IonList, IonPage, IonToast, IonToggle, IonToolbar, RefresherEventDetail, withIonLifeCycle } from '@ionic/react';

//	S T Y L E

import './sb-users-edit.scss';

//	L I B S

import { lib_names } from '../../../libs/lib.names';

//	S T A T E   -   P R O P S

import { props___SbUsersEditPage, state___SbUsersEditPage, state___SbUsersEditPage___default } from './sb-users-edit.state';

//	T Y P E S

import { type___api___response } from '../../../types/types.api-response';
import { type___user_setting___property } from '../../../types/types.types';

//	I N T E R F A C E S

import { interface___IonAlert } from '../../../interfaces/interface.alert';

//	S E R V I C E S

import { service_RestApiService } from '../../../services/service-api';
import { service_Router } from '../../../services/service-router';

//	C O M P O N E N T S

import ComSpinnerComponent from '../../../components/com-spinners/com-spinner/com-spinner';

//	C L A S S

class SbUsersEditPage extends React.Component<props___SbUsersEditPage, state___SbUsersEditPage> {

//#region 																							D E C L A R A T I O N S
		
	private readonly _API: service_RestApiService = new service_RestApiService();
	private readonly _ROUTER: service_Router = new service_Router();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___SbUsersEditPage,
	) {
		super(props);
		this.state = state___SbUsersEditPage___default;
	}

//#endregion

//#region 																							I N T E R F A C E S

	private readonly element___IonAlert___delete_user = () : interface___IonAlert => {
		return {
			header: 'Attenzione, stai per eliminare l\'utente "' + ((this.state.user___props && this.state.user___props['user_username']) ? this.state.user___props['user_username'] : '') + '"',
			message: 'Verranno eliminate tutte le sue attività ma i ticket venduti rimarranno disponibili, vuoi davvero effettuare questa operazione?',
			buttons: [ {
				text: 'Si',
				handler: () => { 
					this.setState({ 
						alert___delete_user___visibility: false,
					});
				}
			}, {
				text: 'Annulla',
				role: 'cancel',
				handler: () => {
					this.setState({ 
						alert___delete_user___visibility: false,
					});
				}
			}]
		}
	};

//#endregion

//#region 																							R E A D   P R O P S

	private read_props___organization = async () => {
		const ___temp___organization___props: type___api___response = await this._API.organizations___read___single();
		if (!___temp___organization___props || ___temp___organization___props.response !== 'success' || ___temp___organization___props.data === null) { console.log('404'); }
		this.setState({
			field___user___username: ___temp___organization___props.data['organization_slug'] + '@',
			organization___props: ___temp___organization___props.data,
			organization___props___is_loaded: false,
		});
	}

	private read_props___user = async () => {
		const ___temp___user___props: type___api___response = await this._API.users___read___single('id', this.state.user___id!);
		if (!___temp___user___props || ___temp___user___props.response !== 'success' || ___temp___user___props.data === null) { console.log('404'); }
		this.setState({
			user___props: ___temp___user___props.data,
			user___props___isLoading: false,
		}, () => {
			this.setState({
				
				field___user___username: this.state.user___props['user_username'],
				
				field___user___name___displayed: this.state.user___props['user_name_displayed'],
				field___user___name: this.state.user___props['user_name'],
				field___user___surname: this.state.user___props['user_surname'],

				field___user___contact_email: this.state.user___props['user_contact_mail'],
				field___user___contact_phone: this.state.user___props['user_contact_phone'],

				field___user___role_is___admin: this.state.user___props['user_role_is___admin'],
				field___user___role_is___cashdesk: this.state.user___props['user_role_is___cashdesk'],
				field___user___role_is___pr: this.state.user___props['user_role_is___pr'],

				user___props___isLoading: false,

			});
		});
	}

//#endregion

//#region 																							H A N D L E R S

	private handle___field_compile___username = (___e: any) => {
		const ___username: string = ___e.target.value as string;
		const ___username___organization_slug: string = this.state.organization___props['organization_slug'] + '@';
		const ___username___unwrapped: string = ___username.replace(___username___organization_slug, '');
		this.setState({
			field___user___username: ___username___organization_slug + ___username___unwrapped,
		});
	}

	private handle___is_changed___role = async () => {
		const ___user_permissions___submitted___list: type___user_setting___property[] = [
			{ key: 'user_role_is___admin', value: this.state.field___user___role_is___admin },
			{ key: 'user_role_is___cashdesk', value: this.state.field___user___role_is___cashdesk },
		];
		const ___user_permissions___submitted: type___api___response = await this._API.users___change_settings(this.state.user___id!, ___user_permissions___submitted___list);
		this.setState({
			toast___user___isSubmitted: (___user_permissions___submitted && ___user_permissions___submitted.response === 'success') ? 'success' : 'error',
			user___props___isLoading: true,
		}, async () => {
			await this.read_props___user();
		});
	}

//#endregion

//#region 																							L I F E C Y C L E

	private submit___new_user = async () => {
		const ___user___is_submitted: type___api___response = await this._API.users___submit(null, this.state.field___user___username, this.state.field___user___name___displayed, this.state.field___user___name, this.state.field___user___surname, this.state.field___user___contact_email, this.state.field___user___contact_phone, this.state.field___user___role_is___admin, this.state.field___user___role_is___cashdesk);
		this.setState({
			toast___user___isSubmitted: (___user___is_submitted && ___user___is_submitted.response === 'success') ? 'success' : 'error',
			user___id: (___user___is_submitted && ___user___is_submitted.data) ? ___user___is_submitted.data : null,
			user___props___isLoading: true,
		}, async () => {
			await this.read_props___user();
		});
	}

//#endregion

//#region 																							S U B M I T

	async ionViewDidEnter() : Promise<void> {
		await this.read_props___organization();
		const ___user___id: string | null = this._ROUTER.get_id(this.props);
		this.setState({
			user___id: (___user___id !== 'new') ? ___user___id : null,
		}, async () => {
			if (this.state.user___id && this.state.user___id !== null && this.state.user___id !== 'new') {
				await this.read_props___user();
			} else {
				this.setState({
					user___props___isLoading: false,
				});	
			}
		});
	}

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode {
		return <>
			<IonPage>

				{(this.state.user___props___isLoading === false && this.state.organization___props___is_loaded === false) ? <>
					<IonAlert isOpen={ this.state.alert___delete_user___visibility } { ...(this.element___IonAlert___delete_user()) }></IonAlert>
				</> : <></>}

				<IonToast isOpen={ this.state.toast___user___isSubmitted === 'success' } message="Il salvataggio dell'utente è andato a buon fine" duration={ 3000 } color='success' position='top' onDidDismiss={() => { this.setState({ toast___user___isSubmitted: 'no-data' }); }}/>
				<IonToast isOpen={ this.state.toast___user___isSubmitted === 'error' } message="Errore nel salvataggio dell'utente" duration={ 3000 } color='danger' position='top' onDidDismiss={() => { this.setState({ toast___user___isSubmitted: 'no-data' }); }}/>

				<IonHeader className="ion-no-border" collapse="fade">
					<IonToolbar>
						<IonButtons slot="start">
							<IonBackButton defaultHref="/users/list"></IonBackButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>

				<IonContent>

					{(this.state.user___props___isLoading === false && this.state.organization___props___is_loaded === false) ? <>
						<div className="sb-page---container sb-users-edit---container">
							{(() => { switch (this.state.user___id) {
								case null: return <>
								
									<h2>Crea nuovo Utente</h2>

									<br />

									<label htmlFor="field---user-name">
										<p className="input---nb-label">username</p>
										<input type="text" id="field---user-username" className="input---nb-outlined" placeholder="organizzazione@username" value={ this.state.field___user___username } onInput={ this.handle___field_compile___username }/>
									</label>

									<br />

									<label htmlFor="field---user-name">
										<p className="input---nb-label">nome visualizzato</p>
										<input type="text" id="field---user-name" className="input---nb-outlined" placeholder="Nome visualizzato" value={ this.state.field___user___name___displayed } onInput={(___e: any) => { this.setState({ field___user___name___displayed: ___e.target.value}); }}/>
									</label>

									<br />

									<label htmlFor="field---user-name">
										<p className="input---nb-label is-required">nome e cognome</p>
										<input type="text" id="field---user-name" className="input---nb-outlined" placeholder="Nome" value={ this.state.field___user___name } onInput={(___e: any) => { this.setState({ field___user___name: ___e.target.value}); }}/>
									</label>
									<label htmlFor="field---user-surname" style={{display:'block',paddingTop:'10pt'}}>
										<input type="text" id="field---user-surname" className="input---nb-outlined" placeholder="Cognome" value={ this.state.field___user___surname } onInput={(___e: any) => { this.setState({ field___user___surname: ___e.target.value}); }}/>
									</label>

									<br />

									<label htmlFor="field---user-name">
										<p className="input---nb-label is-required">contatti</p>
										<input type="text" id="field---user-email" className="input---nb-outlined" placeholder="email@email.com" value={ this.state.field___user___contact_email } onInput={(___e: any) => { this.setState({ field___user___contact_email: ___e.target.value}); }}/>
									</label>
									<label htmlFor="field---user-surname" style={{display:'block',paddingTop:'10pt'}}>
										<input type="text" id="field---user-phone" className="input---nb-outlined" placeholder="+39 333 333 3334" value={ this.state.field___user___contact_phone } onInput={(___e: any) => { this.setState({ field___user___contact_phone: ___e.target.value}); }}/>
									</label>

									<br />

									<p className="input---nb-label is-required">Permessi</p>

									<IonCard>
										<IonList>
											<IonItem>
												<IonToggle checked={ this.state.field___user___role_is___admin } onIonChange={(___e: any) => { this.setState({ field___user___role_is___admin: ___e.detail.checked }); }}>
													<>Amministratore</>
												</IonToggle>
											</IonItem>
											<IonItem>
												<IonToggle checked={ this.state.field___user___role_is___cashdesk } onIonChange={(___e: any) => { this.setState({ field___user___role_is___cashdesk: ___e.detail.checked }); }}>
													<>Cashdesk</>
												</IonToggle>
											</IonItem>
											<IonItem>
												<IonToggle checked={ this.state.field___user___role_is___pr } disabled={ this.state.field___user___role_is___pr } onIonChange={() => { this.setState({ field___user___role_is___pr: true }); }}>
													<>PR</>
												</IonToggle>
											</IonItem>
										</IonList>
									</IonCard>

								</>; break;
								default: return <>
									
									<h2>Modifica Utente</h2>

									<br />

									<div className="sb-users-edit---user-image">
										<img src={ lib_names.endpoints.avatars + this.state.field___user___username } alt="avatar"/>
									</div>

									<br />

									<h5>{ this.state.field___user___username }</h5>
									<h3>
										{ this.state.field___user___name + ' ' + this.state.field___user___surname }
										{(this.state.field___user___name___displayed) ? <>
											<br />
											<span>come "</span>
											{ this.state.field___user___name___displayed }
											<span>"</span>
										</> : <></>}
									</h3>

									<br />

									{(this.state.field___user___contact_email) ? <>
										<h6>{ this.state.field___user___contact_email }</h6>
									</> : <></>}
									{(this.state.field___user___contact_phone) ? <>
										<h6>{ this.state.field___user___contact_phone }</h6>
									</> : <></>}

									<br />
									<br />

									<p className="input---nb-label" style={{textAlign:'center'}}>Permessi</p>

									<IonCard>
										<IonList>
											<IonItem>
												<IonToggle checked={ this.state.field___user___role_is___admin } disabled={ this.state.field___user___role_is___admin && this.state.user___props['user_is_me'] === true } onIonChange={(___e: any) => { this.setState({ field___user___role_is___admin: ___e.detail.checked }, () => { this.handle___is_changed___role(); }); }}>
													<>Amministratore</>
												</IonToggle>
											</IonItem>
											<IonItem>
												<IonToggle checked={ this.state.field___user___role_is___cashdesk } onIonChange={(___e: any) => { this.setState({ field___user___role_is___cashdesk: ___e.detail.checked }, () => { this.handle___is_changed___role(); }); }}>
													<>Cashdesk</>
												</IonToggle>
											</IonItem>
											<IonItem>
												<IonToggle checked={ this.state.field___user___role_is___pr } disabled={ this.state.field___user___role_is___pr } onIonChange={() => { this.setState({ field___user___role_is___pr: true }, () => { this.handle___is_changed___role(); }); }}>
													<>PR</>
												</IonToggle>
											</IonItem>
										</IonList>
									</IonCard>
									
									<br />
									<br />

									<IonCard>
										<IonList>
											<IonItem button={ true } className="is-ion-item-button">
												<i className="fa-solid fa-key"></i>
												<IonLabel>Invia Reset Password</IonLabel>
											</IonItem>
											{(this.state.user___props['user_is_me'] === true) ? <>
												<IonItem button={ true } className="is-ion-item-button" routerLink="/settings/profile">
													<i className="fa-solid fa-edit"></i>
													<IonLabel>Modifica il tuo profilo</IonLabel>
												</IonItem>
											</> : <></>}
										</IonList>
									</IonCard>

									<br />

									{(this.state.user___props['user_is_me'] === true) ? <>
										<IonCard style={{opacity:0.5,textAlign:'center'}}>
											<IonList>
												<IonItem color="danger" className="is-ion-item-button" style={{textAlign:'center'}}>
													<i className="fa-solid fa-ban"></i>
													<IonLabel>Non puoi eliminare te stesso</IonLabel>
												</IonItem>
											</IonList>
										</IonCard>
									</> : <>
										<IonCard>
											<IonList>
												<IonItem color="danger" button={ true } className="is-ion-item-button" style={{textAlign:'center'}} onClick={() => { this.setState({ alert___delete_user___visibility: true }); }}>
													<i className="fa-solid fa-ban"></i>
													<IonLabel>Elimina utente</IonLabel>
												</IonItem>
											</IonList>
										</IonCard>
									</>}

								</>; break;
							}})()}
						</div>
					
					</> : <>

						<br />
						<br />
						<br />
						<br />
						<br />
						<br />

						<ComSpinnerComponent size="small"/>

					</>}

				</IonContent>

				{(this.state.user___id === null && this.state.user___props___isLoading === false) ? <>
					<IonFooter>
						<section className="container---footer-section">
							<button className="is-element is-button is-color---folly is-full-width" onClick={ this.submit___new_user }>
								<i className="fa-solid fa-check"></i>
								<h6>Salva</h6>
							</button>
						</section>
					</IonFooter>
				</> : <></>}

			</IonPage>
		</>;
	}

//#endregion

}

//	E X P O RT

export default withIonLifeCycle(SbUsersEditPage);