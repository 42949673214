import React from 'react';
import { IonBackButton, IonButtons, IonCard, IonContent, IonFooter, IonHeader, IonItem, IonLabel, IonList, IonListHeader, IonPage, IonToggle, IonToolbar, withIonLifeCycle } from '@ionic/react';

//	S T Y L E

import './sb-events-import.scss';

//	S T A T E   -   P R O P S

import { props___SbEventsEditPage, state___SbEventsEditPage, state___SbEventsEditPage___default } from './sb-events-import.state';

//	T Y P E S

import { type___api___response } from '../../../types/types.api-response';

//	S E R V I C E S

import { service_RestApiService } from '../../../services/service-api';
import { service_Router } from '../../../services/service-router';

//	C O M P O N E N T S

import ComEventComponent from '../../../components/com-events/com-event/com-event';
import ComSpinnerComponent from '../../../components/com-spinners/com-spinner/com-spinner';
import ComSpinnerMiniComponent from '../../../components/com-spinners/com-spinner-mini/com-spinner-mini';

//	M O D A L S

import MdTicketTypeEditModal from '../../../modals/md-ticket/md-ticket-type-edit/md-ticket-type-edit';

//	C L A S S

class SbEventsImportPage extends React.Component<props___SbEventsEditPage, state___SbEventsEditPage> {

//#region 																							D E C L A R A T I O N S
		
	private readonly _API: service_RestApiService = new service_RestApiService();
	private readonly _ROUTER: service_Router = new service_Router();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___SbEventsEditPage,
	) {
		super(props);
		this.state = state___SbEventsEditPage___default;
	}

//#endregion

//#region 																							R E A D   P R O P S

	private read_props___event = async () => {
		const ___temp___event___props: type___api___response = await this._API.events___read___single___preview(this.state.event___id!);
		if (!___temp___event___props || ___temp___event___props.response !== 'success' || ___temp___event___props.data === null) { console.log('404'); }
		this.setState({
			event___props: ___temp___event___props.data,
			event___props___is_loading: false
		});
	}

//#endregion

//#region 																							L I F E C Y C L E

	private submit___event_connection = async () => {
		if (
			(this.state.field___ticket___payment === false) ||
			((this.state.field___ticket___payment === true) && this.state.ticket___selling_start && this.state.ticket___selling_stop)
		) {
			const ___event___is_connected: type___api___response = await this._API.events___connect(this.state.event___id, this.state.field___auth_code, this.state.field___ticket___list, this.state.field___ticket___payment, this.state.ticket___selling_start ?? null, this.state.ticket___selling_stop ?? null, this.state.ticket___max_quantity);	
			if (!(___event___is_connected && ___event___is_connected.response === 'success')) {
				alert('Errore nella connessione dell\'evento \n\nProbabilmente il codice di autenticazione è sbagliato o il link che hai seguito non è più disponibile \n\nSe l\'errore persiste contattare l\'assistenza');
			} else {
				if (this.state.field___ticket___payment === false) {
					window.location.href = '/dashboard';
				} else {
					this.setState({
						modal___MdTicketTypeEditModal___isOpen: true,
					});
				}
			}
		} else {
			alert('Errore \n\nCompila tutti i campi');
		}
	}

//#endregion

//#region 																							L I F E C Y C L E

	ionViewDidEnter() : void {
		const ___event___id: string = this._ROUTER.get_id(this.props);
		this.setState({
			event___id: ___event___id,
		}, async () => {
			await this.read_props___event();
		});
	}

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode {
		return <>

			<MdTicketTypeEditModal isOpen={this.state.modal___MdTicketTypeEditModal___isOpen} event_id={this.state.event___id} event___onDidDismiss={() => { this.setState({ modal___MdTicketTypeEditModal___isOpen: false }, () => { window.location.href = '/dashboard'; }) }} />

			<IonPage>

				<IonHeader className="ion-no-border" collapse="fade">
					<IonToolbar>
						<IonButtons slot="start">
							<IonBackButton defaultHref="/dashboard"></IonBackButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>

				<IonContent>
					{(this.state.event___props___is_loading === true) ? <>
						<br />
						<br />
						<br />
						<br />
						<br />
						<br />
						<ComSpinnerComponent size="small"/>
					</> : <>
						<div className="sb-events-import---container">
						
							<IonListHeader>
								<IonLabel>Importa Evento da Nearby</IonLabel>
							</IonListHeader>
						
							<br />
						
							<section>

								<IonCard>
									<ComEventComponent event___props={ this.state.event___props }/>
								</IonCard>

								<br />

								<label htmlFor="field---auth-code">
									<p className="input---nb-label is-required">Codice di autenticazione</p>
									<input type="text" id="field---auth-code" className="input---nb-outlined" placeholder="000000" value={ this.state.field___auth_code } onInput={(___e: any) => { this.setState({ field___auth_code: ___e.target.value}); }}/>
								</label>

								<br />

								<p className="input---nb-label is-required">Tipi di Tickets</p>

								<IonCard>
									<IonList>
										<IonItem>
											<IonToggle checked={ this.state.field___ticket___list } onIonChange={(___e: any) => { this.setState({ field___ticket___list: ___e.detail.checked }); }}>
												<>Abilita Liste Inviti</>
											</IonToggle>
										</IonItem>
										<IonItem>
											<IonToggle checked={ this.state.field___ticket___payment } onIonChange={(___e: any) => { this.setState({ field___ticket___payment: ___e.detail.checked }); }}>
												<>Abilita Ticket a Pagamento</>
											</IonToggle>
										</IonItem>
									</IonList>
								</IonCard>

								{(this.state.field___ticket___payment) ? <>
								
									<br />
									<br />

									<label htmlFor="field---ticket-selling-start">
										<p className="input---nb-label is-required">Apri acquisto dei ticket</p>
										<input type="datetime-local" id="field---ticket-selling-start" className="input---nb-outlined" placeholder="dd/mm/yy hh:mm" defaultValue={ this.state.ticket___selling_start } value={ this.state.ticket___selling_start } onChange={(___e: any) => { this.setState({ ticket___selling_start: ___e.target.value}); }}/>
									</label>

									<label htmlFor="field---ticket-selling-end">
										<p className="input---nb-label is-required">Chiudi acquisto dei ticket</p>
										<input type="datetime-local" id="field---ticket-selling-end" className="input---nb-outlined" placeholder="dd/mm/yy hh:mm" defaultValue={ this.state.ticket___selling_stop } value={ this.state.ticket___selling_stop } onChange={(___e: any) => { this.setState({ ticket___selling_stop: ___e.target.value}); }}/>
									</label>

									<label htmlFor="field---ticket-quantity-max">
										<p className="input---nb-label">Quantità di ticket disponibili (0 = illimitati)</p>
										<input type="number" id="field---ticket-quantity-max" className="input---nb-outlined" step="a" value={ this.state.ticket___max_quantity } onChange={(___e: any) => { this.setState({ ticket___max_quantity: ___e.target.value}); }}/>
									</label>

								</> : <></>}

							</section>
			
							<br />
							<br />
							<br />

						</div>
					</>}
				</IonContent>

				<IonFooter>
					{(this.state.event___props___is_loading !== false) ? <>
						<ComSpinnerMiniComponent />
					</> : <>
						<section className="container---footer-section" onClick={ this.submit___event_connection }>
							<button className="is-element is-button is-color---folly is-full-width">
								<i className="fa-solid fa-check"></i>
								<h6>Importa Evento</h6>
							</button>
						</section>
					</>}
				</IonFooter>

			</IonPage>
		</>;
	}

//#endregion

}

//	E X P O R T 

export default withIonLifeCycle(SbEventsImportPage);