export const locale___SbSettingsMainPage = {
	'alert___logout___title': {
		de_de: "Warte",
		en_us: "Wait",
		es_es: "Espera",
		fr_fr: "Attends",
		it_it: "Aspetta"
	},
	'alert___logout___message': {
		de_de: "Möchtest du dich wirklich abmelden?",
		en_us: "Do you really want to log out?",
		es_es: "¿Realmente quieres cerrar sesión?",
		fr_fr: "Voulez-vous vraiment vous déconnecter ?",
		it_it: "Vuoi davvero effettuare il logout?"
	},
	'alert___logout___button_yes': {
		de_de: "Ja",
		en_us: "Yes",
		es_es: "Sí",
		fr_fr: "Oui",
		it_it: "Si"
	},
	'alert___logout___button_cancel': {
		de_de: "Abbrechen",
		en_us: "Cancel",
		es_es: "Cancelar",
		fr_fr: "Annuler",
		it_it: "Annulla"
	},
	'account___title': {
		de_de: "Konto",
		en_us: "Account",
		es_es: "Cuenta",
		fr_fr: "Compte",
		it_it: "Account"
	},
	'account___profile': {
		de_de: "Profil",
		en_us: "Profile",
		es_es: "Perfil",
		fr_fr: "Profil",
		it_it: "Profilo"
	},
	'account___auth': {
		de_de: "Authentifizierung",
		en_us: "Authentication",
		es_es: "Autenticación",
		fr_fr: "Authentification",
		it_it: "Autenticazione"
	},
	'account___logout': {
		de_de: "Abmelden",
		en_us: "Logout",
		es_es: "Cerrar sesión",
		fr_fr: "Déconnexion",
		it_it: "Logout"
	},
	'theme___title': {
		de_de: "Thema",
		en_us: "Theme",
		es_es: "Tema",
		fr_fr: "Thème",
		it_it: "Tema"
	},
	'theme___settings': {
		de_de: "Themen-Einstellungen",
		en_us: "Theme Settings",
		es_es: "Configuración del tema",
		fr_fr: "Paramètres du thème",
		it_it: "Impostazioni del tema"
	},
	'info___title': {
		de_de: "Info",
		en_us: "Info",
		es_es: "Información",
		fr_fr: "Infos",
		it_it: "Info"
	},
	'info___eula': {
		de_de: "Nutzungsbedingungen",
		en_us: "Terms and Conditions",
		es_es: "Términos y Condiciones",
		fr_fr: "Termes et Conditions",
		it_it: "Termini e Condizioni"
	},
	'info___cookies': {
		de_de: "Cookies und Datenschutz",
		en_us: "Cookies and Privacy",
		es_es: "Cookies y Privacidad",
		fr_fr: "Cookies et Confidentialité",
		it_it: "Cookies e Privacy"
	},
	'info___version': {
		de_de: "App-Version",
		en_us: "App Version",
		es_es: "Versión de la App",
		fr_fr: "Version de l'App",
		it_it: "Versione App"
	},
	'info___copyright': {
		de_de: "Urheberrecht",
		en_us: "Copyright",
		es_es: "Derechos de Autor",
		fr_fr: "Droits d'Auteur",
		it_it: "Copyright"
	},
	'info___licence': {
		de_de: "Lizenzen",
		en_us: "Licenses",
		es_es: "Licencias",
		fr_fr: "Licences",
		it_it: "Licenze"
	},

	/*
		'____________________________': {
			de_de: '____________________________',
			en_us: '____________________________',
			es_es: '____________________________',
			fr_fr: '____________________________',
			it_it: '____________________________',
		},
	*/

};