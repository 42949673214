import React from 'react';
import { IonAlert, IonBadge, IonButton, IonButtons, IonCard, IonContent, IonFooter, IonHeader, IonItem, IonLabel, IonList, IonListHeader, IonModal, IonToolbar, withIonLifeCycle } from '@ionic/react';

// L O C A L E

import { locale___MdTicketEditModal } from './md-ticket-edit.locale';

//	S T Y L E

import './md-ticket-edit.scss';

//	I N T E R F A C E S

import { interface___IonAlert } from '../../../interfaces/interface.alert';

//	L I B S

import { lib_names } from '../../../libs/lib.names';

//	S T A T E   -   P R O P S

import { props___MdTicketEditModal, state___MdTicketEditModal, state___MdTicketEditModal___default } from './md-ticket-edit.state';

//	T Y P E S

import { type___api___response } from '../../../types/types.api-response';

//	S E R V I C E S

import { service_RestApiService } from '../../../services/service-api';
import { service_LocalizationService } from '../../../services/service-localization';

//	C O M P O N E N T S

import ComEventComponent from '../../../components/com-events/com-event/com-event';
import ComSpinnerComponent from '../../../components/com-spinners/com-spinner/com-spinner';
import ComSpinnerMiniComponent from '../../../components/com-spinners/com-spinner-mini/com-spinner-mini';

//	C L A S S

class MdTicketEditModal extends React.Component<props___MdTicketEditModal, state___MdTicketEditModal> {

//#region 																							C O N F I G

	private readonly MdTicketEditModal___modal_config: any = {
		breakpoints: [0, 1],
		backdropDismiss: true,
		handle: true,
		initialBreakpoint: 1,
		keyboardClose: true,
		showBackdrop: true,
		onDidPresent: () => { this.event___onDidPresent() },
	};

//#endregion

//#region 																							D E C L A R A T I O N S
	
	private readonly _LOCALE: service_LocalizationService = new service_LocalizationService();
	private readonly _API: service_RestApiService = new service_RestApiService();

//#endregion

//#region 																							I N T E R F A C E S

	private readonly element___IonAlert___cancel_ticket = () : interface___IonAlert => {
		return {
			header: this._LOCALE.translate(locale___MdTicketEditModal.alert___cancel_ticket___title),
			message: this._LOCALE.translate(locale___MdTicketEditModal.alert___cancel_ticket___message),
			buttons: [{
				text: this._LOCALE.translate(locale___MdTicketEditModal.alert___cancel_ticket___yes),
				handler: () => {
					this.setState({
						...state___MdTicketEditModal___default
					}, () => {
						this.props.event___onDidDismiss(null);
					});
				}
			}, {
				text: this._LOCALE.translate(locale___MdTicketEditModal.alert___cancel_ticket___no),
				role: 'cancel',
				handler: () => { this.setState({ alert___askForClose: false });}
			}]
		};
	};

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___MdTicketEditModal,
	) {
		super(props);
		this.state = state___MdTicketEditModal___default;
	}

//#endregion

//#region 																							R E A D   P R O P S

	private read_props___ticket = async () => {
		if (this.props.ticket_id && this.props.ticket_id !== null) {
			const ___temp___ticket_props: type___api___response = await this._API.tickets___read___single(this.props.ticket_id);
			this.setState({
				ticket___person_name: (___temp___ticket_props.response === 'success') ? ___temp___ticket_props['data']['ticket_person_name'] : '',
				ticket___event_id: (___temp___ticket_props.response === 'success') ? ___temp___ticket_props['data']['ticket_event'] : '',
				ticket___notes: (___temp___ticket_props.response === 'success') ? ___temp___ticket_props['data']['ticket_notes'] : '',
				ticket___props___isLoading: false,
			}, async () => {
				await this.read_props___event();
			});
		} else {
			this.setState({
				ticket___props___isLoading: false,
			}, async () => {
				await this.read_props___event();
			});
		}
	}

	private read_props___event = async () => {
		const ___tosearch___event_id: string | null = localStorage.getItem(lib_names.localStorage.user___event_selected);
		if (___tosearch___event_id) {
			const ___temp___event_props: type___api___response = await this._API.events___read___single(___tosearch___event_id);
			this.setState({
				ticket___event_id: ___tosearch___event_id,
				ticket___event_props: ___temp___event_props.data,
				ticket___event_props___isLoading: false
			});
		} else {
			this.props.event___onDidDismiss();
		}
	}

//#endregion

//#region 																							H A N D L E R S

	private handle___MdTicketEditModal___askClose = () => {
		this.setState({
			alert___askForClose: true
		});
	}

//#endregion

//#region 																							S U B M I T

	private submit___ticket = () => {
		this.setState({
			ticket___props___isLoading: true
		}, async () => {
			const ___ticket_is_submitted: type___api___response = await this._API.tickets___submit(this.props.ticket_id, this.state.ticket___event_id!, this.state.ticket___person_name, this.state.ticket___person_email, this.state.ticket___notes);
			if (___ticket_is_submitted['response'] === 'success') {
				this.props.event___onDidDismiss(___ticket_is_submitted['data']);
			} else {

				
				
				
				
				
				
				console.log('probkena oorijsjhndjjsjndknaojjln');










			}
		});
	}

//#endregion

//#region 																							L I F E C Y C L E

	async event___onDidPresent() : Promise<void> {
		const ___me_props: type___api___response = await this._API.users___read___single('me');
		if (!___me_props || ___me_props['response'] !== 'success') { console.log('problems in user'); }
		this.setState({
			...state___MdTicketEditModal___default,
			me___props: ___me_props.data,
			me___props___is_loading: true,
		}, async () => {
			await this.read_props___ticket();
		});
	}

//#endregion

//#region 																							R E T U R N

	render() : React.ReactNode {
		return <>

			<IonAlert isOpen={ this.state.alert___askForClose } { ...(this.element___IonAlert___cancel_ticket()) }></IonAlert>

			<IonModal isOpen={ this.props.isOpen } { ...this.MdTicketEditModal___modal_config }>

				<IonHeader className="ion-no-border" collapse="fade">
					<IonToolbar>
						<IonButtons slot="end">
							<IonButton onClick={ this.handle___MdTicketEditModal___askClose }>{ this._LOCALE.translate(locale___MdTicketEditModal.button___cancel) }</IonButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>
				
				<IonContent>

					<IonListHeader>
						{(this.props.ticket_id === null) ? <>
							<IonLabel>{ this._LOCALE.translate(locale___MdTicketEditModal.title___new) }</IonLabel>
						</> : <>
							<IonLabel>{ this._LOCALE.translate(locale___MdTicketEditModal.title___edit) }</IonLabel>
						</>}
					</IonListHeader>

					<section className="md-ticket-edit---container">
						
						{(this.state.ticket___props___isLoading === true) ? <>
							<ComSpinnerMiniComponent />
						</> : <>

							<br />

							<p className="input---nb-label">{ this._LOCALE.translate(locale___MdTicketEditModal.selected_event) }</p>
							{(this.state.ticket___event_props___isLoading === true) ? <>
								<ComSpinnerComponent size="small"/>
							</> : <>
								{(this.state.ticket___event_props !== null) ? <>
									<IonCard>
										<ComEventComponent event___props={ this.state.ticket___event_props }/>
									</IonCard>
								</> : <>
									<IonCard>
										<IonList>
											<IonItem>
												<IonLabel>{ this._LOCALE.translate(locale___MdTicketEditModal.selected_event___none) }</IonLabel>
											</IonItem>
										</IonList>
									</IonCard>
								</>}
							</>}

							<br />

							<p className="input---nb-label is-required">{ this._LOCALE.translate(locale___MdTicketEditModal.ticket_type) }</p>
							<IonCard>
								<IonList>
									<IonItem className="is-ion-item-button">
										<i className="fas fa-calendar-week"></i>
										<IonLabel>{ this._LOCALE.translate(locale___MdTicketEditModal.ticket_type___invite) }</IonLabel>
										<IonBadge>{ this._LOCALE.translate(locale___MdTicketEditModal.ticket_type___invite___name_in_list) }</IonBadge>
									</IonItem>
								</IonList>
							</IonCard>

							<br />

							<label htmlFor="field---ticket-person-name">
								<p className="input---nb-label is-required">{ this._LOCALE.translate(locale___MdTicketEditModal.field___name_surname) }</p>
								<input type="text" id="field---ticket-person-name" className="input---nb-outlined" placeholder={ this._LOCALE.translate(locale___MdTicketEditModal.field___name_surname___placeholder) } value={ this.state.ticket___person_name } onInput={(___e: any) => { this.setState({ ticket___person_name: ___e.target.value}); }}/>
							</label>

							<br />

							<label htmlFor="field---ticket-person-name">
								<p className="input---nb-label">{ this._LOCALE.translate(locale___MdTicketEditModal.field___email) }</p>
								<input type="text" id="field---ticket-person-name" className="input---nb-outlined" placeholder={ this._LOCALE.translate(locale___MdTicketEditModal.field___email___placeholder) } value={ this.state.ticket___person_email } onInput={(___e: any) => { this.setState({ ticket___person_email: ___e.target.value}); }}/>
								<p>{this._LOCALE.translate(locale___MdTicketEditModal.field___email___description)}</p>
							</label>

							<br />

							<label htmlFor="field---event-description">
								<p className="input---nb-label">{ this._LOCALE.translate(locale___MdTicketEditModal.field___notes) }</p>
								<textarea id="field---event-description" className="input---nb-outlined" placeholder={ this._LOCALE.translate(locale___MdTicketEditModal.field___notes___placeholder) } style={{height:'150pt'}} value={ this.state.ticket___notes } onInput={(___e: any) => { this.setState({ ticket___notes: ___e.target.value}); }}></textarea>
							</label>

						</>}

					</section>

				</IonContent>
				
				{(this.state.ticket___props___isLoading !== true) ? <>
					<IonFooter>
						<section className="container---footer-section">
							<button className="is-element is-button is-color---folly is-full-width" onClick={ this.submit___ticket }>
								<i className="fa-solid fa-check"></i>
								<h4>{ this._LOCALE.translate(locale___MdTicketEditModal.button___save) }</h4>
							</button>
						</section>
					</IonFooter>
				</> : <></>}

			</IonModal>

		</>;
	}

//#endregion

}

//	E X P O R T

export default withIonLifeCycle(MdTicketEditModal);