import React from 'react';
import { IonActionSheet, IonBackButton, IonButtons, IonCard, IonContent, IonHeader, IonPage, IonRefresher, IonToolbar, RefresherEventDetail, withIonLifeCycle } from '@ionic/react';

//	S T Y L E

import './sb-events-list.scss';

//	I N T E R F A C E S

import { interface___IonActionSheet } from '../../../interfaces/interface.actionsheet';

//	S T A T E   -   P R O P S

import { props___SbEventsListPage, state___SbEventsListPage, state___SbEventsListPage___default } from './sb-events-list.state';

//	L I B S

import { lib_names } from '../../../libs/lib.names';

//	T Y P E S

import { type___api___response } from '../../../types/types.api-response';

//	S E R V I C E S

import { service_RestApiService } from '../../../services/service-api';

//	C O M P O N E N T S

import ComEventComponent from '../../../components/com-events/com-event/com-event';
import ComSpinnerMiniComponent from '../../../components/com-spinners/com-spinner-mini/com-spinner-mini';

//	C L A S S

class SbEventsListPage extends React.Component<props___SbEventsListPage, state___SbEventsListPage>
{

//#region 																							D E C L A R A T I O N S
		
	private readonly _API: service_RestApiService = new service_RestApiService();

//#endregion

//#region 																							I N T E R F A C E S

	private readonly element___IonActionSheet___options = () : interface___IonActionSheet => {
		const ___button___view: any[] = (this.state.actionSheet___event_options___target) ? [{
			text: 'Visualizza',
			handler: () => { 
				const ___url___event: string = '/events/view/' + this.state.actionSheet___event_options___target['event_id'];
				window.location.href = ___url___event;
			}
		}] : [];
//		const ___button___edit: any[] = (this.state.actionSheet___event_options___target && this.state.actionSheet___event_options___target['event_ended'] === false) ? [{
//			text: 'Modifica',
//			handler: () => { 
//				const { history } = this.props;
//				history.push('/events/edit/' + this.state.actionSheet___event_options___target['event_id']);
//			}
//		}] : [];
		const ___button___select: any[] = (this.state.actionSheet___event_options___target) ? [{
			text: 'Seleziona come evento principale',
			handler: () => { 
				localStorage.setItem(lib_names.localStorage.user___event_selected, this.state.actionSheet___event_options___target['event_id']);
				this.handle___page____refresh();
			}
		}] : [];
		return { buttons: [
			...___button___view,
//			...___button___edit,
			...___button___select,
			{
				text: 'Annulla',
				role: 'cancel',
			}
		]};
	};

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___SbEventsListPage,
	) {
		super(props);
		this.state = state___SbEventsListPage___default;
	}

//#endregion

//#region 																							R E A D   P R O P S

	read_props___events___collection = async () => {
		const ___temp___selected_event_props: type___api___response = await this._API.events___read___multi('organization', null, this.state.event___collection.length);
		this.setState({
			event___collection: ___temp___selected_event_props.data,
			event___collection___loading: false
		});
	}

//#endregion

//#region 																							H A N D L E R S

	handle___page____refresh = (___e: CustomEvent<RefresherEventDetail> | null = null) => {
		this.setState({
			event___id___selected: localStorage.getItem(lib_names.localStorage.user___event_selected),
			event___collection: [],
			event___collection___loading: true,
		}, async () => {
			await this.read_props___events___collection();
			if (___e) { ___e.detail.complete(); }
		});
	}

	handle___event___options = async (___event___id: string) => {
		this.setState({
			actionSheet___event_options___target: ___event___id,
			actionSheet___event_options: true
		}); 
	}

//#endregion

//#region 																							L I F E C Y C L E

	ionViewDidEnter() : void {
		const ___event___id___selected: string | null = localStorage.getItem(lib_names.localStorage.user___event_selected);
		this.setState({
			event___collection: [],
			event___collection___loading: true,
			event___id___selected: ___event___id___selected,
		}, async () => {
			await this.read_props___events___collection();
		});
	}

//#endregion

//#region 																							R E T U R N

	render() : React.ReactNode
	{

		return <>

			<IonActionSheet isOpen={ this.state.actionSheet___event_options } { ...(this.element___IonActionSheet___options()) } onDidDismiss={() => { this.setState({ actionSheet___event_options: false }); }}></IonActionSheet>

			<IonPage>

				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonBackButton defaultHref="/dashboard"></IonBackButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>

				<IonContent>

					<IonRefresher slot="fixed" onIonRefresh={ this.handle___page____refresh }>
						<ComSpinnerMiniComponent/>
					</IonRefresher>

					<section className="sb-events-list---container">

						<h2>I tuoi Eventi</h2>

						<br />

						{/** 
							{(this.props.me && this.props.me['user_role_is___admin'] === true) ? <>
								<IonCard>
									<IonList>
										<IonItem button={ true } className="is-ion-item-button" routerLink="/events/create">
											<i className="fas fa-plus"></i>
											<IonLabel><b>Crea nuovo</b></IonLabel>
										</IonItem>
									</IonList>
								</IonCard>
								<br />
							</> : <></>}
						**/}

						{(this.state.event___collection___loading === true) ? <>
							<ComSpinnerMiniComponent/>
						</> : <>
							{this.state.event___collection.map((___e: any, ___e___idx: number) => {
								const ___e___is_selected: boolean = (___e['event_id'] === this.state.event___id___selected) ? true : false;
								const ___e___is_selected___class: string = (___e___is_selected === true) ? ' sb-event-list---event-selected-card ' : ' ';
								return <>
									<IonCard key={ ___e___idx } className={ ___e___is_selected___class } onClick={() => { this.handle___event___options(___e); }}>
										<ComEventComponent event___props={ ___e }/>
									</IonCard>
									<br />
								</>;
							})}
						</>}

					</section>

				</IonContent>

			</IonPage>

		</>;

	}

//#endregion

}

//	E X P O R T

export default withIonLifeCycle(SbEventsListPage);