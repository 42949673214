import React from 'react';
import { IonAlert, IonBadge, IonButton, IonButtons, IonCard, IonContent, IonFooter, IonHeader, IonItem, IonLabel, IonList, IonListHeader, IonModal, IonToolbar, withIonLifeCycle } from '@ionic/react';

//	S T Y L E

import './md-ticket-type-edit.style.scss';

//	I N T E R F A C E S

import { interface___IonAlert } from '../../../interfaces/interface.alert';

//	L I B S

import { lib_names } from '../../../libs/lib.names';

//	S T A T E   -   P R O P S

import { props___MdTicketTypeEditModal, state___MdTicketTypeEditModal, state___MdTicketTypeEditModal___default } from './md-ticket-type-edit.state';

//	T Y P E S

import { type___api___response } from '../../../types/types.api-response';

//	S E R V I C E S

import { service_LocalizationService } from '../../../services/service-localization';
import { service_RestApiService } from '../../../services/service-api';

//	C O M P O N E N T S

import ComSpinnerComponent from '../../../components/com-spinners/com-spinner/com-spinner';
import ComEventComponent from '../../../components/com-events/com-event/com-event';
import ComSpinnerMiniComponent from '../../../components/com-spinners/com-spinner-mini/com-spinner-mini';
import { locale___MdTicketTypeEditModal } from './md-ticket-type-edit.locale';

//	C L A S S

class MdTicketTypeEditModal extends React.Component<props___MdTicketTypeEditModal, state___MdTicketTypeEditModal> {

//#region 																							C O N F I G

	private readonly MdTicketTypeEditModal___modal_config: any = {
		breakpoints: [0, 1],
		backdropDismiss: true,
		handle: true,
		initialBreakpoint: 1,
		keyboardClose: true,
		showBackdrop: true,
		onDidPresent: () => { this.event___onDidPresent() },
		onDidDismiss: () => { this.props.event___onDidDismiss(); }
	};

//#endregion

//#region 																							D E C L A R A T I O N S

	private readonly _LOCALE: service_LocalizationService = new service_LocalizationService();
	private readonly _API: service_RestApiService = new service_RestApiService();

//#endregion

//#region 																							I N T E R F A C E S

	private readonly element___IonAlert___cancel_ticket = () : interface___IonAlert => {
		return {
			header: this._LOCALE.translate(locale___MdTicketTypeEditModal.alert___cancel___title),
			message: this._LOCALE.translate(locale___MdTicketTypeEditModal.alert___cancel___message),
			buttons: [{
				text: this._LOCALE.translate(locale___MdTicketTypeEditModal.alert___cancel___button___yes),
				handler: () => {
					this.setState({
						...state___MdTicketTypeEditModal___default
					}, () => {
						this.props.event___onDidDismiss(null);
					});
				}
			}, {
				text: this._LOCALE.translate(locale___MdTicketTypeEditModal.alert___cancel___button___no),
				role: 'cancel',
				handler: () => { this.setState({ alert___askForClose: false });}
			}]
		};
	};

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___MdTicketTypeEditModal,
	) {
		super(props);
		this.state = state___MdTicketTypeEditModal___default;
	}

//#endregion

//#region 																							R E A D   P R O P S

/*	read_props___ticket = async () => {
		if (this.props.ticket_id && this.props.ticket_id !== null) {
			const ___temp___ticket_props: type___api___response = await this._API.tickets___read___single(this.props.ticket_id);
			this.setState({
				ticket_type___person_name: (___temp___ticket_props.response === 'success') ? ___temp___ticket_props['data']['ticket_person_name'] : '',
				ticket_type___event_id: (___temp___ticket_props.response === 'success') ? ___temp___ticket_props['data']['ticket_event'] : '',
				ticket_type___notes: (___temp___ticket_props.response === 'success') ? ___temp___ticket_props['data']['ticket_notes'] : '',
				ticket_type___props___isLoading: false,
			}, async () => {
				await this.read_props___event();
			});
		} else {
			this.setState({
				ticket_type___props___isLoading: false,
			}, async () => {
				await this.read_props___event();
			});
		}
	}
*/

	private read_props___event = async () => {
		const ___temp___event_id: string | null = (this.props.event_id && this.props.event_id !== null) ? this.props.event_id : localStorage.getItem(lib_names.localStorage.user___event_selected);
		if (___temp___event_id) {
			const ___temp___event_props: type___api___response = await this._API.events___read___single___preview(___temp___event_id);
			this.setState({
				ticket_type___event_id: ___temp___event_id,
				ticket_type___event_props: ___temp___event_props.data,
				ticket_type___event_props___isLoading: false
			});
		} else {
			this.props.event___onDidDismiss();
		}
	}

//#endregion

//#region 																							H A N D L E R S

	private handle___MdTicketTypeEditModal___askClose = () => {
		this.setState({
			alert___askForClose: true
		});
	}

//#endregion

//#region 																							S U B M I T

	private submit___ticket = () => {
		this.setState({
			ticket_type___props___isLoading: true
		}, async () => {
			const ___ticket_is_submitted: type___api___response = await this._API.tickets_types___submit(null, this.state.ticket_type___event_id, this.state.ticket_type___name, this.state.ticket_type___description, this.state.ticket_type___price, this.state.ticket_type___max_quantity);
			if (___ticket_is_submitted['response'] === 'success') {
				this.props.event___onDidDismiss(___ticket_is_submitted['data']);
			} else {

				
				

				
				
				
				console.log('probkena oorijsjhndjjsjndknaojjln');










			}
		});
	}

//#endregion

//#region 																							L I F E C Y C L E

	async event___onDidPresent() : Promise<void> {
		const ___me_props: type___api___response = await this._API.users___read___single('me');
		if (!___me_props || ___me_props['response'] !== 'success') { console.log('problems in user'); }
		this.setState({
			me___props: ___me_props.data,
			me___props___is_loading: true,
			ticket_type___props___isLoading: false
		}, async () => {
	//		await this.read_props___ticket();
			await this.read_props___event();
		});
	}

//#endregion

//#region 																							R E T U R N

	render() : React.ReactNode {
		return <>

			<IonAlert isOpen={ this.state.alert___askForClose } { ...(this.element___IonAlert___cancel_ticket()) }></IonAlert>

			<IonModal isOpen={ this.props.isOpen } { ...this.MdTicketTypeEditModal___modal_config }>

				<IonHeader className="ion-no-border" collapse="fade">
					<IonToolbar>
						<IonButtons slot="end">
							<IonButton onClick={ this.handle___MdTicketTypeEditModal___askClose }>annulla</IonButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>
				
				<IonContent>

					<IonListHeader>
						<IonLabel>{this._LOCALE.translate(locale___MdTicketTypeEditModal.title___page)}</IonLabel>
					</IonListHeader>

					<section className="md-ticket-edit---container">
						
						{(this.state.ticket_type___props___isLoading === true) ? <>
							<ComSpinnerMiniComponent />
						</> : <>

							<br />

							<p className="input---nb-label">{this._LOCALE.translate(locale___MdTicketTypeEditModal.title___event)}</p>
							{(this.state.ticket_type___event_props___isLoading === true) ? <>
								<ComSpinnerComponent size="small"/>
							</> : <>
								{(this.state.ticket_type___event_props !== null) ? <>
									<IonCard>
										<ComEventComponent event___props={ this.state.ticket_type___event_props }/>
									</IonCard>
								</> : <>
									<p>{this._LOCALE.translate(locale___MdTicketTypeEditModal.title___event___error)}</p>
								</>}
							</>}

							<br />

							<label htmlFor="field---ticket-person-name">
								<p className="input---nb-label is-required">{this._LOCALE.translate(locale___MdTicketTypeEditModal.field___name)}</p>
								<input type="text" id="field---ticket-person-name" className="input---nb-outlined" placeholder={this._LOCALE.translate(locale___MdTicketTypeEditModal.field___name___placeholder)} value={ this.state.ticket_type___name } onInput={(___e: any) => { this.setState({ ticket_type___name: ___e.target.value}); }}/>
							</label>

							<br />

							<label htmlFor="field---event-description">
								<p className="input---nb-label">{this._LOCALE.translate(locale___MdTicketTypeEditModal.field___description)}</p>
								<textarea id="field---event-description" className="input---nb-outlined" placeholder={this._LOCALE.translate(locale___MdTicketTypeEditModal.field___description___placeholder)} style={{height:'150pt'}} value={ this.state.ticket_type___description } onInput={(___e: any) => { this.setState({ ticket_type___description: ___e.target.value}); }}></textarea>
							</label>

							<br />

							<label htmlFor="field---ticket-person-price">
								<p className="input---nb-label is-required">{this._LOCALE.translate(locale___MdTicketTypeEditModal.field___price)}</p>
								<section style={{display:'flex'}}>
									<input type="number" id="field---ticket-person-price" className="input---nb-outlined" placeholder="0.00" value={ this.state.ticket_type___price } onInput={(___e: any) => { this.setState({ ticket_type___price: ___e.target.value}); }}/>
									<span>€</span>
								</section>
							</label>

							<br />

							<label htmlFor="field---ticket-quantity-max">
								<p className="input---nb-label">{this._LOCALE.translate(locale___MdTicketTypeEditModal.field___quantity)}</p>
								<input type="number" id="field---ticket-quantity-max" className="input---nb-outlined" step="1" value={ this.state.ticket_type___max_quantity } onChange={(___e: any) => { this.setState({ ticket_type___max_quantity: ___e.target.value}); }}/>
							</label>

							<br />
							<br />
							<br />

						</>}

					</section>

				</IonContent>
				
				<IonFooter>
					<section className="container---footer-section">
						<button className="is-element is-button is-color---folly is-full-width" onClick={ this.submit___ticket }>
							<i className="fa-solid fa-check"></i>
							<h4>{this._LOCALE.translate(locale___MdTicketTypeEditModal.button___save)}</h4>
						</button>
					</section>
				</IonFooter>

			</IonModal>

		</>;
	}

//#endregion

}

//	E X P O R T

export default withIonLifeCycle(MdTicketTypeEditModal);