import { interface___modal } from "../../../interfaces/interface.modals";

//	P R O P S

export interface props___MdSignPasswordChangeModal extends interface___modal {

}

//	S T A T E

export interface state___MdSignPasswordChangeModal {

	field___password: string,
	field___password___repeat: string,

	password_change___is_loading: boolean,

	toast___password_change_success___visible: boolean,
	toast___password_change_unmatch___visible: boolean,
	toast___password_change_error___visible: boolean,

}

//	S T A T E   D E F A U L T

export const state___MdSignPasswordChangeModal___default: state___MdSignPasswordChangeModal = {

	field___password: '',
	field___password___repeat: '',

	password_change___is_loading: false,

	toast___password_change_success___visible: false,
	toast___password_change_unmatch___visible: false,
	toast___password_change_error___visible: false,

}