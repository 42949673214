import { interface___modal } from "../../../interfaces/interface.modals";

//	P R O P S

export interface props___MdTicketViewModal extends interface___modal {

	ticket_id: string | null,

}

//	S T A T E

export interface state___MdTicketViewModal {

	ticket___id: string,
	ticket___props: any | null,
	ticket___props___isLoading: boolean,

	ticket_type___props: any | null,
	ticket_type___props___isLoading: boolean,

	user___props: any | null,
	user___props___isLoading: boolean,

	event___props: any | null,
	event___props___isLoading: boolean,

}

//	S T A T E   D E F A U L T

export const state___MdTicketViewModal___default: state___MdTicketViewModal = {

	ticket___id: '',
	ticket___props: null,
	ticket___props___isLoading: true,

	ticket_type___props: null,
	ticket_type___props___isLoading: true,

	user___props: null,
	user___props___isLoading: true,

	event___props: null,
	event___props___isLoading: true,

}