import React from 'react'

//	S T Y L E

import './com-copyright.scss'

//	C L A S S

export default class ComCopyrightComponent extends React.Component<{}, {}>
{

//#region 																							R E N D E R

	render() : React.ReactNode
	{

		return <>
			<div className="com-copyright-component---container">
				<span><b>© Copyright 2016 - { (new Date()).getFullYear().toString() } by PRYSM</b></span>
				<span><b>NoPR</b> è un marchio registrato di proprietà esclusiva di PRYSM</span>
				<span>Ogni riproduzione anche parziale non autorizzata è vietata</span>
			</div>
		</>;

	}

//#endregion

}