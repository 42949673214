import { interface___modal } from "../../interfaces/interface.modals";

//	P R O P S

export interface props___MdShareUrlModal extends interface___modal {

}

//	S T A T E

export interface state___MdShareUrlModal {

	url_to_share: string,
	url_to_share___shareBy: boolean,

	event___props: any | null,
	event___props___isLoading: boolean,

	user___props: any | null,
	user___props___isLoading: boolean,

	toast___shared___isOpen: boolean,

}

//	S T A T E   D E F A U L T

export const state___MdShareUrlModal___default: state___MdShareUrlModal = {

	url_to_share: '',
	url_to_share___shareBy: true,

	event___props: null,
	event___props___isLoading: true,

	user___props: null,
	user___props___isLoading: true,

	toast___shared___isOpen: false,

}