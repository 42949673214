import React from 'react';

//	S T Y L E

import './com-qr-view.scss'

//	L I B S

import { lib_names } from '../../libs/lib.names';

//	P R O P S

import { props___ComQrViewComponent } from './com-qr-view.state';

//	C L A S S

export default class ComQrViewComponent extends React.Component<props___ComQrViewComponent, {}> {

//#region 																							R E N D E R

	render() : React.ReactNode {
		return <>
			<div className="com-qr-view-component---container">
				<div>
					<img src={ lib_names.endpoints.qrs_generator + this.props.qrCode_content }/>
				</div>
			</div>
		</>;
	}

//#endregion

}