import { interface___modal } from "../../../interfaces/interface.modals";

//	P R O P S

export interface props___MdTicketEditModal extends interface___modal {

	ticket_id: string | null

}

//	S T A T E

export interface state___MdTicketEditModal {

	alert___askForClose: boolean,

	me___props: any | null,
	me___props___is_loading: boolean,

	ticket___event_id: string | null,
	ticket___event_props: any | null,
	ticket___event_props___isLoading: boolean,

	ticket___person_name: string,
	ticket___person_email: string,
	ticket___notes: string,

	ticket___props___isLoading: boolean,

}

//	S T A T E   D E F A U L T

export const state___MdTicketEditModal___default: state___MdTicketEditModal = {

	alert___askForClose: false,

	me___props: null,
	me___props___is_loading: true,

	ticket___event_id: null,
	ticket___event_props: null,
	ticket___event_props___isLoading: true,

	ticket___person_name: '',
	ticket___person_email: '',
	ticket___notes: '',

	ticket___props___isLoading: true,

}