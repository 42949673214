export const locale___SbCashdeskPage = {
	'not_available___attention': {
		de_de: "Achtung!",
		en_us: "Attention!",
		es_es: "¡Atención!",
		fr_fr: "Attention !",
		it_it: "Attenzione!"
	},
	'not_available___text': {
		de_de: "Dieses Event ist für die Kasse nicht verfügbar, das Datum entspricht nicht dem heutigen Datum",
		en_us: "This event is not available for the cashdesk, the date does not match today's date",
		es_es: "Este evento no está disponible para la caja, la fecha no coincide con la fecha de hoy",
		fr_fr: "Cet événement n'est pas disponible pour la caisse, la date ne correspond pas à la date d'aujourd'hui",
		it_it: "questo evento non è disponibile per il cashdesk, la data non corrisponde alla data odierna"
	},
	'not_available___use': {
		de_de: "Trotzdem die Kasse benutzen",
		en_us: "Use the cashdesk anyway",
		es_es: "Usar la caja de todos modos",
		fr_fr: "Utiliser quand même la caisse",
		it_it: "usa comunque il cashdesk"
	},
	'list___view___label': {
		de_de: "Anzeigen",
		en_us: "View",
		es_es: "Ver",
		fr_fr: "Afficher",
		it_it: "Visualizza"
	},
	'list___view___all_tickets': {
		de_de: "Alle Tickets",
		en_us: "All Tickets",
		es_es: "Todas las Entradas",
		fr_fr: "Tous les Billets",
		it_it: "Tutti i Ticket"
	},
	'list___view___not_used': {
		de_de: "Nur ungenutzte",
		en_us: "Only Unused",
		es_es: "Solo no usados",
		fr_fr: "Seulement non utilisés",
		it_it: "Solo non usati"
	},
	'bottom_bar___qrCode': {
		de_de: "QR-Code",
		en_us: "QR Code",
		es_es: "Código QR",
		fr_fr: "QR Code",
		it_it: "QR Code"
	},
	'bottom_bar___search': {
		de_de: "Name suchen",
		en_us: "Search Name",
		es_es: "Buscar Nombre",
		fr_fr: "Rechercher un Nom",
		it_it: "Cerca Nome"
	}

	/*
		'________________': {
			de_de: "________________",
			en_us: "________________",
			es_es: "________________",
			fr_fr: "________________",
			it_it: "________________"
		},
	*/

};