export const locale___SbTicketsTypesPage = {
	'title___event': {
		de_de: "Ausgewähltes Event",
		en_us: "Selected Event",
		es_es: "Evento Seleccionado",
		fr_fr: "Événement Sélectionné",
		it_it: "Evento Selezionato"
	},
	'title___tickets_types': {
		de_de: "Ticket-Typen",
		en_us: "Ticket Types",
		es_es: "Tipos de Entradas",
		fr_fr: "Types de Billets",
		it_it: "Tipi di Ticket"
	},
	'button___add_ticket': {
		de_de: "Ticket-Typ hinzufügen",
		en_us: "Add Ticket Type",
		es_es: "Agregar Tipo de Entrada",
		fr_fr: "Ajouter un Type de Billet",
		it_it: "Aggiungi tipo di Ticket"
	}

	/*
		'____________________________': {
			de_de: '____________________________',
			en_us: '____________________________',
			es_es: '____________________________',
			fr_fr: '____________________________',
			it_it: '____________________________',
		},
	*/

};