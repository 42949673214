export const locale___MdShareUrlModal = {
	'share_title': {
		de_de: "Link teilen",
		en_us: "Share Link",
		es_es: "Compartir enlace",
		fr_fr: "Partager le lien",
		it_it: "Condividi Link"
	},
	'share_text': {
		de_de: "Kaufe dein Ticket für {{1}} über diesen Link {{2}}",
		en_us: "Buy your ticket for {{1}} from this link {{2}}",
		es_es: "Compra tu entrada para {{1}} desde este enlace {{2}}",
		fr_fr: "Achetez votre billet pour {{1}} via ce lien {{2}}",
		it_it: "Acquista il tuo ticket per {{1}} da questo link {{2}}"
	},
	'share_url_description': {
		de_de: "Kopiere den Einladungslink",
		en_us: "Copy the invitation link",
		es_es: "Copia el enlace de la invitación",
		fr_fr: "Copier le lien d'invitation",
		it_it: "Copia il link all'invito"
	},
	'share_enable_invite_by': {
		de_de: "Lade mich ein",
		en_us: "Include Invitation from Me",
		es_es: "Incluir invitación de mi parte",
		fr_fr: "Inclure une invitation de ma part",
		it_it: "Includi Invito da Me"	
	},
	'share_button': {
		de_de: "Teilen",
		en_us: "Share",
		es_es: "Compartir",
		fr_fr: "Partager",
		it_it: "Condividi"
	},
};