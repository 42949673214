import { interface___page } from "../../../interfaces/interface.page"

//	P R O P S

export interface props___SbTicketsListPage extends interface___page {
	
}

//	S T A T E

export interface state___SbTicketsListPage {

	event___id: string,
	event___props: any | null,
	event___props___isLoading: boolean,

	tickets___collection___target: 'all' | 'all_used' | 'me' | 'me_used',
	tickets___collection___sort: 'standard' | 'date_added_asc' | 'date_added_desc' | 'name_asc' | 'name_desc', 
	tickets___collection: any[],
	tickets___collection___isLoading: boolean,

	
	actionSheet___ticket_selected___visibile: boolean,
	alert___delete_ticket___visibility: boolean,



	modal___MdTicketViewModal___isOpen: boolean
	modal___MdTicketViewModal___argument: string | null,

}

//	S T A T E   D E F A U L T

export const state___SbTicketsListPage___default: state___SbTicketsListPage = {

	event___id: '',
	event___props: null,
	event___props___isLoading: true,

	tickets___collection___target: 'me',
	tickets___collection___sort: 'standard', 
	tickets___collection: [],
	tickets___collection___isLoading: true,


	actionSheet___ticket_selected___visibile: false,
	alert___delete_ticket___visibility: false,





	modal___MdTicketViewModal___isOpen: false,
	modal___MdTicketViewModal___argument: null,

}