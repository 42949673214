import React from 'react';
import { IonCard, IonContent, IonFooter, IonModal, withIonLifeCycle } from '@ionic/react';

//	S T Y L E

import './md-cashdesk-checkout.scss';

//	S T A T E   -   P R O P S

import { props___MdCashDeskCheckoutModal, state___MdCashDeskCheckoutModal, state___MdCashDeskCheckoutModal___default } from './md-cashdesk-checkout.state';

//	T Y P E S

import { type___api___response } from '../../../types/types.api-response';

//	S E R V I C E S

import { service_RestApiService } from '../../../services/service-api';

//	C O M P O N E N T S

import ComEventComponent from '../../../components/com-events/com-event/com-event';
import ComSpinnerMiniComponent from '../../../components/com-spinners/com-spinner-mini/com-spinner-mini';
import ComTicketValidComponent from '../../../components/com-tickets/com-ticket-valid/com-ticket-valid';
import ComTicketTypeComponent from '../../../components/com-tickets/com-ticket-type/com-ticket-type';

//	C L A S S

class MdCashDeskCheckoutModal extends React.Component<props___MdCashDeskCheckoutModal, state___MdCashDeskCheckoutModal> {

//#region 																							C O N F I G

	private readonly MdCashDeskCheckoutModal___modal_config: any = {
		backdropDismiss: false,
		handle: false,
		keyboardClose: false,
		showBackdrop: true,
		onDidPresent: () => { this.event___onDidPresent(); },
		onDidDismiss: () => { this.event___onDidDismiss(); }
	};

//#endregion

//#region 																							D E C L A R A T I O N S
		
	private readonly _API: service_RestApiService = new service_RestApiService();

	private readonly audio___blip_valid: HTMLAudioElement = new Audio('assets/audio/blip_valid.mp3');
	private readonly audio___blip_error: HTMLAudioElement = new Audio('assets/audio/blip_error.mp3');

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___MdCashDeskCheckoutModal,
	) {
		super(props);
		this.state = state___MdCashDeskCheckoutModal___default;
	}

//#endregion

//#region 																							R E A D   P R O P S

	private read_props___ticket_type = async () => {
		const ___temp___ticket_type_props: type___api___response = await this._API.tickets_types___read___single(this.state.ticket___props['ticket_type_reference']);
		this.setState({
			ticket___props___type_props: ___temp___ticket_type_props.data,
			ticket___props___type_props___isLoading: false
		});
	}

	private read_props___pr = async () => {
		if (this.state.ticket___props['ticket_pr'] !== null) {
			const ___temp___pr___props: type___api___response = await this._API.users___read___single('id', this.state.ticket___props['ticket_pr']);
			this.setState({
				pr___props: ___temp___pr___props.data,
				pr___props___isLoading: false,
			});
		} else {
			this.setState({
				pr___props: null,
				pr___props___isLoading: false,
			});
		}
	}

//#endregion

//#region 																							H A N D L E R S

	private handle___button___onCheckout = async (___should_check: boolean = false) => {
		if (___should_check === true) {
			const ___temp___ticket_checkout: type___api___response = await this._API.cashdesk___ticket___validate_and_use(this.state.event___props['event_id'], this.state.ticket___props['ticket_code']);
		
			console.log('___temp___ticket_checkout', ___temp___ticket_checkout)






			if (___temp___ticket_checkout && ___temp___ticket_checkout.response === 'success') {
				if ('vibrate' in navigator) { navigator.vibrate([300]); }
				this.audio___blip_valid.play();
			} else {
				this.audio___blip_error.play();
			}
		}
		this.props.event___onDidDismiss(___should_check);
	}

//#endregion

//#region 																							L I F E C Y C L E

	private async event___onDidPresent() : Promise<void> {
		if (this.props.ticket_props) {
			this.setState({
				event___props: this.props.event_props,
				ticket___props: this.props.ticket_props,
			}, async () => {
				if (this.state.ticket___props['ticket_type'] !== null) {
					await this.read_props___ticket_type();
				} else {
					await this.read_props___pr();
				}
			});
		} else {
			this.props.event___onDidDismiss(false);
		}
	}

	private event___onDidDismiss() : void {
		this.setState({
			...state___MdCashDeskCheckoutModal___default
		})
	}

//#endregion

//#region 																							R E T U R N

	render() : React.ReactNode {
		return <>
			<IonModal isOpen={ this.props.isOpen } { ...this.MdCashDeskCheckoutModal___modal_config }>
				{(this.state.ticket___props && this.state.ticket___props !== null) ? <>
					<IonContent>
						<section className="md-cashdesk-checkout---container">
							
							<br />
							
							<ComTicketValidComponent ticket_type='ticket' ticket_valid={ true }/>

							{(this.state.ticket___props['ticket_type'] === null) ? <>
								<IonCard>
									{(this.state.pr___props___isLoading === true) ? <>
										<ComSpinnerMiniComponent/>
									</> : <>
										<h6>Invito inserito da</h6>
										<h4>{ this.state.pr___props['user_name'] + ' ' + this.state.pr___props['user_surname'] }</h4>
										<br />
									</>}
								</IonCard>
							</> : <>
								{(this.state.ticket___props___type_props___isLoading === true) ? <>
									<ComSpinnerMiniComponent/>
								</> : <>
									{(this.state.ticket___props___type_props !== null) ? <>
										<IonCard>
											<ComTicketTypeComponent ticket_type___props={ this.state.ticket___props___type_props }/>	
										</IonCard>
									</> : <></>}
								</>}
							</>}

							<ComEventComponent event___props={this.state.event___props}/>

							<br />
							<br />
							
							<h6>Intestato a</h6>
							<h2>{ this.state.ticket___props['ticket_person_name'] }</h2>
							
							<br />
							<br />
						
							{(this.state.ticket___props['ticket_notes'] && this.state.ticket___props['ticket_notes'].length > 0) ? <>
								<h6>Note:</h6>
								<p>{ this.state.ticket___props['ticket_notes'] }</p>
							</> : <></>}
						</section>
					</IonContent>

					<IonFooter>
						<section className="container---footer-section container---footer-section---check-in">
							<button className="is-element is-button is-color---danger is-full-width" onClick={() => { this.handle___button___onCheckout(false); }}>
								<h3>ANNULLA</h3>
							</button>
							<button className="is-element is-button is-color---success is-full-width" onClick={() => { this.handle___button___onCheckout(true); }}>
								<h3>CONVALIDA</h3>
							</button>
						</section>
					</IonFooter>

				</> : <>
				
					null error
				
				</>}
			</IonModal>
		</>;
	}

//#endregion

}

//	E X P O R T

export default withIonLifeCycle(MdCashDeskCheckoutModal);