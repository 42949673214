import React, { createRef } from 'react';
import { IonButton, IonButtons, IonCard, IonContent, IonFooter, IonHeader, IonItem, IonList, IonModal, IonToggle, IonToolbar, withIonLifeCycle } from '@ionic/react';
import { shareOnMobile } from "react-mobile-share";

//	S T Y L E

import './md-share-url.scss';

//	L I B S

import { lib_names_shared } from '../../libs/lib.names.shared';

//	S T A T E   -   P R O P S

import { props___MdShareUrlModal, state___MdShareUrlModal, state___MdShareUrlModal___default } from './md-share-url.state';

//	F U N C S

import { funcs_datetime } from '../../funcs/funcs.datetime';

//	T Y P E S

import { type___api___response } from '../../types/types.api-response';

//	S E R V I C E S

import { service_RestApiService } from '../../services/service-api';
import { service_LocalizationService } from '../../services/service-localization';

//	C O M P O N E N T S

import ComButtonTicketShareComponent from '../../components/com-button-ticket-share/com-button-ticket-share';
import ComQrViewComponent from '../../components/com-qr-view/com-qr-view';
import ComSpinnerComponent from '../../components/com-spinners/com-spinner/com-spinner';
import ComTicketTypeComponent from '../../components/com-tickets/com-ticket-type/com-ticket-type';
import ComSpinnerMiniComponent from '../../components/com-spinners/com-spinner-mini/com-spinner-mini';
import { locale___MdShareUrlModal } from './md-share-url.locale';
import { lib_names } from '../../libs/lib.names';
import ComEventNullComponent from '../../components/com-events/com-event-null/com-event-null';
import ComEventComponent from '../../components/com-events/com-event/com-event';
import ComEventTimeScheduleComponent from '../../components/com-events/com-event-timeschedule/com-event-timeschedule';

//	C L A S S

class MdShareUrlModal extends React.Component<props___MdShareUrlModal, state___MdShareUrlModal> {

//#region 																							C O N F I G

	private readonly MdShareUrlModal___modal_config: any = {
		breakpoints: [0, 1],
		backdropDismiss: true,
		handle: true,
		initialBreakpoint: 1,
		keyboardClose: true,
		showBackdrop: true,
		onDidPresent: () => { this.event___onDidPresent(); },
	};

//#endregion

//#region 																							D E C L A R A T I O N S
	
	private readonly _LOCALE: service_LocalizationService = new service_LocalizationService();
	private readonly _API: service_RestApiService = new service_RestApiService();

	private readonly funcs___datetime: funcs_datetime = new funcs_datetime();

	private MdShareUrlModal___ticket_dom_reference: React.RefObject<HTMLDivElement> = createRef<HTMLDivElement>();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___MdShareUrlModal,
	) {
		super(props);
		this.state = state___MdShareUrlModal___default;
	}

//#endregion

//#region 																							R E A D   P R O P S

	private read_props___me = async () => {
		const ___temp___me: type___api___response = await this._API.users___read___single('me', null);
		this.setState({
			user___props: ___temp___me.data,
			user___props___isLoading: false,
		}, async () => {
			await this.read_props___event();
		});
	}

	private read_props___event = async () => {
		const ___stored___event_id: string | null = localStorage.getItem(lib_names.localStorage.user___event_selected);
		if (___stored___event_id !== null) {
			const ___temp___selected_event_props: type___api___response = await this._API.events___read___single(___stored___event_id);
			this.setState({
				event___props: ___temp___selected_event_props.data,
				event___props___isLoading: false
			}, () => {
				this.handle___url___prepare();
			});
		} else {
			this.setState({
				event___props___isLoading: false
			});
		}
	}

//#endregion

//#region 																							H A N D L E R S

	private handle___url___prepare = () => {
		let ___temp___url_to_share: string = lib_names.endpoints.ticket_buy + this.state.event___props['event_id'];
		if (this.state.url_to_share___shareBy === true) { ___temp___url_to_share += '/' + this.state.user___props['user_id']; }
		this.setState({ url_to_share: ___temp___url_to_share });
	}

	private handle___url___share = async () => {
		try {
			shareOnMobile({
				text: this._LOCALE.translate(locale___MdShareUrlModal.share_text, [this.state.event___props['event_name'], this.state.url_to_share]),
				url: this.state.url_to_share,
				title: this.state.event___props['event_name'],
			});
		} catch (___e) {
			if (navigator.share) {
				try {
					await navigator.share({
						title: this._LOCALE.translate(locale___MdShareUrlModal.share_text, [this.state.event___props['event_name']]),
						text: this._LOCALE.translate(locale___MdShareUrlModal.share_text, [this.state.event___props['event_name'], this.state.url_to_share]),
						url: this.state.url_to_share
					});
					return 'shared';
				} catch (___e_n: any) {
					console.log('Error sharing   -->   ', ___e_n);
				}
			}
			try {
				await navigator.clipboard.writeText(this.state.url_to_share);
				return 'copyed';
			} catch (___e_c: any) {
				console.error('Error copyng   -->   ', ___e_c);
			}
		}
	}

//#endregion

//#region 																							L I F E C Y C L E

	private async event___onDidPresent() : Promise<void> {
		await this.read_props___me();
	}

//#endregion

//#region 																							R E T U R N

	render() : React.ReactNode {
		return <>
			<IonModal isOpen={ this.props.isOpen } { ...this.MdShareUrlModal___modal_config }>

				<IonHeader className="ion-no-border" collapse="fade">
					<IonToolbar>
						<IonButtons slot="end">
							<IonButton onClick={ this.props.event___onDidDismiss }>chiudi</IonButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>

				<IonContent>
					<section className="md-share-url---container">

						<h2>{ this._LOCALE.translate(locale___MdShareUrlModal.share_title) }</h2>

						<br />

						{(this.state.event___props___isLoading === true) ? <>
							<ComSpinnerComponent size="small"/>
						</> : <>
							{(this.state.event___props) ? <>
								<ComEventTimeScheduleComponent event___props={ this.state.event___props } />
								<br />
								<IonCard>
									<ComEventComponent event___props={ this.state.event___props }/>
								</IonCard>
							</> : <>
								<ComEventNullComponent />
							</>}
						</>}

						<br />
						<br />
						<br />

						<p className="input---nb-label">{ this._LOCALE.translate(locale___MdShareUrlModal.share_url_description) }</p>
						<div className="input---nb-outlined">{ this.state.url_to_share }</div>

					</section>
				</IonContent>

				<IonFooter>
					<IonList>
						<IonItem>
							<IonToggle checked={ this.state.url_to_share___shareBy } onIonChange={(___e: any) => { this.setState({ url_to_share___shareBy: ___e.detail.checked }, () => { this.handle___url___prepare(); }); }}>
								<b>{ this._LOCALE.translate(locale___MdShareUrlModal.share_enable_invite_by) }</b>
							</IonToggle>
						</IonItem>
					</IonList>
					<br />
					<section className="container---footer-section">
						<button className="is-element is-button is-color---folly is-full-width" onClick={() => { this.handle___url___share(); }}>
							<i className="fa-solid fa-share"></i>
							<h4>{ this._LOCALE.translate(locale___MdShareUrlModal.share_button) }</h4>
						</button>
					</section>
				</IonFooter>

			</IonModal>
		</>;
	}

//#endregion

}

//	E X P O R T

export default withIonLifeCycle(MdShareUrlModal);