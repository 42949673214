import { RouteComponentProps } from "react-router";

//	P R O P S

export interface props___SbEventsEditPage extends RouteComponentProps<{ id: string; }> {
	
	id?: string

}

//	S T A T E

export interface state___SbEventsEditPage {

	event___id: string | null,
	event___props: any | null,
	event___props___is_loading: boolean,

	field___auth_code: string,
	field___ticket___list: boolean,
	field___ticket___payment: boolean,

	ticket___selling_start: string | undefined,
	ticket___selling_stop: string | undefined,
	ticket___max_quantity: number,

	modal___MdTicketTypeEditModal___isOpen: boolean,







}

//	S T A T E   D E F A U L T

export const state___SbEventsEditPage___default: state___SbEventsEditPage = {

	event___id: null,
	event___props: null,
	event___props___is_loading: true,

	field___auth_code: '',
	field___ticket___list: true,
	field___ticket___payment: false,

	ticket___selling_start: undefined,
	ticket___selling_stop: undefined,
	ticket___max_quantity: 0,

	modal___MdTicketTypeEditModal___isOpen: false,





}