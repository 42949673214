import React from 'react';
import { IonAlert, IonBackButton, IonButtons, IonCard, IonContent, IonHeader, IonItem, IonLabel, IonList, IonListHeader, IonPage, IonToolbar } from '@ionic/react';

//	L O C A L E

import { locale___SbSettingsMainPage } from './sb-settings-main.locale';

//	S T Y L E

import './sb-settings-main.scss';

//	I N T E R F A C E S

import { interface___IonAlert } from '../../../interfaces/interface.alert';

//	P R O P S   -   S T A T E S

import { state___MdTicketEditModal, state___MdTicketEditModal___default } from './sb-settings-main.state';

//	S E R V I C E S

import { service_RestAuthService } from '../../../services/service-auth';
import { service_LocalizationService } from '../../../services/service-localization';

//	C L A S S

export default class SbSettingsMainPage extends React.Component<{}, state___MdTicketEditModal> {

//#region 																							D E C L A R A T I O N S

	private readonly _LOCALE: service_LocalizationService = new service_LocalizationService();
	private readonly _AUTH: service_RestAuthService = new service_RestAuthService();

//#endregion

//#region 																							I N T E R F A C E S

	private readonly SbSettingsMainPage___IonAlert___logout_check: interface___IonAlert = {
		header: this._LOCALE.translate(locale___SbSettingsMainPage.alert___logout___title),
		message: this._LOCALE.translate(locale___SbSettingsMainPage.alert___logout___message),
		buttons: [{
			text: this._LOCALE.translate(locale___SbSettingsMainPage.alert___logout___button_yes),
			handler: () => { 
				this._AUTH.auth___logout();
				this.setState({ alert___logout_check___visibility: false });
			}
		},
		{
			text: this._LOCALE.translate(locale___SbSettingsMainPage.alert___logout___button_cancel),
			role: 'cancel',
			handler: () => {
				this.setState({ alert___logout_check___visibility: false });
			}
		}]
	};

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: {},
	) {
		super(props);
		this.state = state___MdTicketEditModal___default;
	}

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode {
		return <>

			<IonAlert isOpen={ this.state.alert___logout_check___visibility } { ...this.SbSettingsMainPage___IonAlert___logout_check }></IonAlert>

			<IonPage>

				<IonHeader className="ion-no-border" collapse="fade">
					<IonToolbar>
						<IonButtons slot="start">
							<IonBackButton defaultHref="/dashboard"></IonBackButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>

				<IonContent>

					<section className="sb-settings-main---container">

						<IonListHeader>
							<IonLabel>{this._LOCALE.translate(locale___SbSettingsMainPage.account___title)}</IonLabel>
						</IonListHeader>
						
						<br />

						<IonCard>
							<IonList>
								<IonItem button={ true } className="is-ion-item-button" routerLink="/settings/profile">
									<i className="fas fa-user"></i>
									<IonLabel>{this._LOCALE.translate(locale___SbSettingsMainPage.account___profile)}</IonLabel>
								</IonItem>
								<IonItem button={ true } className="is-ion-item-button" routerLink="/settings/authentication">
									<i className="fas fa-lock"></i>
									<IonLabel>{this._LOCALE.translate(locale___SbSettingsMainPage.account___auth)}</IonLabel>
								</IonItem>
								<IonItem className="is-ion-item-button" onClick={() => { this.setState({ alert___logout_check___visibility: true }); }}>
									<i className="fas fa-power-off"></i>
									<IonLabel>{this._LOCALE.translate(locale___SbSettingsMainPage.account___logout)}</IonLabel>
								</IonItem>
							</IonList>
						</IonCard>

						<br />
						<br />

						<IonListHeader>
							<IonLabel>{this._LOCALE.translate(locale___SbSettingsMainPage.theme___title)}</IonLabel>
						</IonListHeader>

						<br />

						<IonCard>
							<IonList>
								<IonItem button={ true } className="is-ion-item-button" routerLink="/settings/theme">
									<i className="fas fa-palette"></i>
									<IonLabel>{this._LOCALE.translate(locale___SbSettingsMainPage.theme___settings)}</IonLabel>
								</IonItem>
							</IonList>
						</IonCard>

						{/**
							<br />
							<h2>Organizzazione</h2>
							<br />

							<IonCard>
								<IonList>
									<IonItem button={ true } className="is-ion-item-button" routerLink="/settings/organization">
										<i className="fas fa-badge"></i>
										<IonLabel>Impostazioni Organizzazione</IonLabel>
									</IonItem>
									<IonItem button={ true } className="is-ion-item-button" routerLink="/settings/organization/payments">
										<i className="fas fa-dollar"></i>
										<IonLabel>Impostazioni Pagamenti</IonLabel>
									</IonItem>
								</IonList>
							</IonCard>
						*/}

						<br />
						<br />

						<IonListHeader>
							<IonLabel>{this._LOCALE.translate(locale___SbSettingsMainPage.info___title)}</IonLabel>
						</IonListHeader>

						<br />

						<IonCard>
							<IonList>
								<IonItem button={ true } className="is-ion-item-button" routerLink="/info/eula">
									<i className="fas fa-balance-scale"></i>
									<IonLabel>{this._LOCALE.translate(locale___SbSettingsMainPage.info___eula)}</IonLabel>
								</IonItem>
								<IonItem button={ true } className="is-ion-item-button" routerLink="/info/eula">
									<i className="fas fa-cookie-bite"></i>
									<IonLabel>{this._LOCALE.translate(locale___SbSettingsMainPage.info___cookies)}</IonLabel>
								</IonItem>
								<IonItem button={ true } className="is-ion-item-button" routerLink="/info/app">
									<i className="fas fa-code-branch"></i>
									<IonLabel>{this._LOCALE.translate(locale___SbSettingsMainPage.info___version)}</IonLabel>
								</IonItem>
								<IonItem button={ true } className="is-ion-item-button" routerLink="/info/copyright">
									<i className="fas fa-copyright"></i>
									<IonLabel>{this._LOCALE.translate(locale___SbSettingsMainPage.info___copyright)}</IonLabel>
								</IonItem>
								<IonItem button={ true } className="is-ion-item-button" routerLink="/info/licences">
									<i className="fas fa-key"></i>
									<IonLabel>{this._LOCALE.translate(locale___SbSettingsMainPage.info___licence)}</IonLabel>
								</IonItem>
							</IonList>
						</IonCard>

					</section>

				</IonContent>

			</IonPage>

		</>;
	}

//#endregion

}