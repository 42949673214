export const locale___MdTicketTypeEditModal = {
	'alert___cancel___title': {
		de_de: "Achtung",
		en_us: "Attention",
		es_es: "Atención",
		fr_fr: "Attention",
		it_it: "Attenzione"
	},
	'alert___cancel___message': {
		de_de: "Möchtest du die Änderungen an dieser Ticketkategorie verwerfen?",
		en_us: "Do you want to discard changes to this Ticket category?",
		es_es: "¿Quieres eliminar los cambios en esta categoría de Ticket?",
		fr_fr: "Voulez-vous annuler les modifications de cette catégorie de Billet ?",
		it_it: "Vuoi eliminare le modifiche a questa categoria di Ticket?"
	},
	'alert___cancel___button___yes': {
		de_de: "Ja",
		en_us: "Yes",
		es_es: "Sí",
		fr_fr: "Oui",
		it_it: "Si"
	},
	'alert___cancel___button___no': {
		de_de: "Abbrechen",
		en_us: "Cancel",
		es_es: "Cancelar",
		fr_fr: "Annuler",
		it_it: "Annulla"
	},
	'title___page': {
		de_de: "Ticketkategorie bearbeiten",
		en_us: "Edit Ticket Category",
		es_es: "Editar Categoría de Ticket",
		fr_fr: "Modifier la Catégorie de Billet",
		it_it: "Modifica Categoria di Ticket"
	},
	'title___event': {
		de_de: "Ausgewähltes Event",
		en_us: "Selected Event",
		es_es: "Evento Seleccionado",
		fr_fr: "Événement Sélectionné",
		it_it: "Evento selezionato"
	},
	'title___event___error': {
		de_de: "Fehler",
		en_us: "Error",
		es_es: "Error",
		fr_fr: "Erreur",
		it_it: "errore"
	},
	'field___name': {
		de_de: "Name der Ticketkategorie",
		en_us: "Ticket Category Name",
		es_es: "Nombre de la Categoría de Ticket",
		fr_fr: "Nom de la Catégorie de Billet",
		it_it: "Nome della Categoria di Ticket"
	},
	'field___name___placeholder': {
		de_de: "Standard Ticketkategorie",
		en_us: "Standard Ticket Category",
		es_es: "Categoría de Ticket Estándar",
		fr_fr: "Catégorie de Billet Standard",
		it_it: "Categoria Ticket Standard"
	},
	'field___description': {
		de_de: "Beschreibung der Ticketkategorie",
		en_us: "Ticket Category Description",
		es_es: "Descripción de la Categoría de Ticket",
		fr_fr: "Description de la Catégorie de Billet",
		it_it: "Descrizione della Categoria di Ticket"
	},
	'field___description___placeholder': {
		de_de: "Beschreibe, was mit dieser Ticketkategorie möglich ist",
		en_us: "Describe what can be done with this Ticket Category",
		es_es: "Escribe qué se puede hacer con esta Categoría de Ticket",
		fr_fr: "Décrivez ce qu'il est possible de faire avec cette Catégorie de Billet",
		it_it: "Scrivi cosa è possibile fare con questa Categoria Ticket"
	},
	'field___price': {
		de_de: "Preis",
		en_us: "Price",
		es_es: "Precio",
		fr_fr: "Prix",
		it_it: "Prezzo"
	},
	'field___quantity': {
		de_de: "Begrenze verfügbare Ticketanzahl (0 = unbegrenzt)",
		en_us: "Limit available ticket quantity (0 = unlimited)",
		es_es: "Limitar la cantidad de tickets disponibles (0 = ilimitados)",
		fr_fr: "Limiter le nombre de billets disponibles (0 = illimité)",
		it_it: "limita quantità di ticket disponibili (0 = illimitati)"
	},
	'button___save': {
		de_de: "Ticket speichern",
		en_us: "Save Ticket",
		es_es: "Guardar Ticket",
		fr_fr: "Enregistrer le Billet",
		it_it: "Salva Ticket"
	}


	/*
		'____________________________': {
			de_de: '____________________________',
			en_us: '____________________________',
			es_es: '____________________________',
			fr_fr: '____________________________',
			it_it: '____________________________',
		},
	*/

};