import React from 'react'

//	S T Y L E

import './com-spinner-mini.scss'

//	C L A S S

export default class ComSpinnerMiniComponent extends React.Component<{}, {}> {

//#region 																							R E N D E R

	render() : React.ReactNode {
		return <>
			<div className="com-spinner-mini---component">
				<i className="fa-solid fa-arrows-rotate"></i>
			</div>
		</>;
	}

//#endregion

}