import { lib_names } from '../libs/lib.names';

//	E X P O R T

export class service_RestAuthService {

//#region 																							D E C L A R A T I O N S

	private _HEADERS: any = { 'Content-Type': 'application/json' };

//#endregion

//#region 																							R E T U R N   S E R V E R   R E S P O N S E

	private async return_server_response(___target: string, ___to_post: any | null) : Promise<any>
	{
		const ___s_route: string = lib_names.endpoints.server_api + 'auth/' + ___target;
		const ___s_response: Response = await fetch(___s_route, { method: 'POST', headers: this._HEADERS, body: JSON.stringify(___to_post) });
		if (___s_response.ok) { const ___server_response: any = await ___s_response.json(); return ___server_response; }
		else { try { const ___error_details: any = await ___s_response.json(); return ___error_details.message; } catch (___e: any) { return ___e; }}
	}

//#endregion

//#region 																							C H E C K   I F   I S   L O G G E D

	public async auth___checklogged() : Promise<any>
	{
		const ___to_post: any = { 'token': localStorage.getItem(lib_names.localStorage.user___logintoken) ?? null };
		return await this.return_server_response('checklogged', ___to_post);
	}

//#endregion

//#region 																							L O G I N

	public async auth___login(___username: string, ___password: string) : Promise<any>
	{
		const ___to_post: any = { 'username': ___username, 'password': ___password };
		return await this.return_server_response('login', ___to_post);
	}

//#endregion

//#region 																							R E S E T   P A S S W O R D

	public async auth___perform___reset_password(___email: string) : Promise<any>
	{
		const ___to_post: any = { 'user_email': ___email };
		return await this.return_server_response('reset_password', ___to_post);
	}

//#endregion

//#region 																							L O G O U T

	public auth___logout() : void
	{
		localStorage.removeItem(lib_names.localStorage.user___cookies_accepted);
		localStorage.removeItem(lib_names.localStorage.user___event_selected);
		localStorage.removeItem(lib_names.localStorage.user___logintoken);
		window.location.href = '/dashboard';
	}

//#endregion

}