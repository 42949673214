export const locale___ComButtonTicketShareComponent = {
	'share_text': {
		de_de: "Lade dein Ticket für {{1}} über diesen Link {{2}} herunter und teile es mit niemandem",
		en_us: "Download your ticket for {{1}} from this link {{2}} and do not share it with anyone",
		es_es: "Descarga tu ticket para {{1}} desde este enlace {{2}} y no lo compartas con nadie",
		fr_fr: "Téléchargez votre billet pour {{1}} via ce lien {{2}} et ne le partagez avec personne",
		it_it: "Scarica il tuo ticket per {{1}} da questo link {{2}} e non condividerlo con nessuno"
	},
	'share_button': {
		de_de: "Teilen",
		en_us: "Share",
		es_es: "Compartir",
		fr_fr: "Partager",
		it_it: "Condividi"
	},
};