export const locale___MdTicketEditModal = {
	'alert___cancel_ticket___title': {
		de_de: "Achtung",
		en_us: "Warning",
		es_es: "Atención",
		fr_fr: "Attention",
		it_it: "Attenzione"
	},
	'alert___cancel_ticket___message': {
		de_de: "Möchtest du die Änderungen an diesem Ticket löschen?",
		en_us: "Do you want to discard changes to this Ticket?",
		es_es: "¿Quieres eliminar los cambios en este Ticket?",
		fr_fr: "Voulez-vous supprimer les modifications apportées à ce billet ?",
		it_it: "Vuoi eliminare le modifiche a questo Ticket?"
	},
	'alert___cancel_ticket___yes': {
		de_de: "Ja",
		en_us: "Yes",
		es_es: "Sí",
		fr_fr: "Oui",
		it_it: "Si"
	},
	'alert___cancel_ticket___no': {
		de_de: "Abbrechen",
		en_us: "Cancel",
		es_es: "Cancelar",
		fr_fr: "Annuler",
		it_it: "Annulla"
	},
	'button___cancel': {
		de_de: "Abbrechen",
		en_us: "Cancel",
		es_es: "Cancelar",
		fr_fr: "Annuler",
		it_it: "annulla"
	},
	'button___save': {
		de_de: "Ticket speichern",
		en_us: "Save Ticket",
		es_es: "Guardar Ticket",
		fr_fr: "Enregistrer le billet",
		it_it: "Salva Ticket"
	},
	'title___new': {
		de_de: "Neues Ticket",
		en_us: "New Ticket",
		es_es: "Nuevo Ticket",
		fr_fr: "Nouveau Billet",
		it_it: "Nuovo Ticket"
	},
	'title___edit': {
		de_de: "Ticket bearbeiten",
		en_us: "Edit Ticket",
		es_es: "Editar Ticket",
		fr_fr: "Modifier le Billet",
		it_it: "Modifica Ticket"
	},
	'selected_event': {
		de_de: "Ausgewähltes Event",
		en_us: "Selected Event",
		es_es: "Evento seleccionado",
		fr_fr: "Événement sélectionné",
		it_it: "Evento selezionato"
	},
	'selected_event___none': {
		de_de: "Kein Event ausgewählt",
		en_us: "No event selected",
		es_es: "Ningún evento seleccionado",
		fr_fr: "Aucun événement sélectionné",
		it_it: "Nessun evento selezionato"
	},
	'ticket_type': {
		de_de: "Ticket-Typ",
		en_us: "Ticket Type",
		es_es: "Tipo de Ticket",
		fr_fr: "Type de Billet",
		it_it: "Tipologia del Ticket"
	},
	'ticket_type___invite': {
		de_de: "Einladung",
		en_us: "Invitation",
		es_es: "Invitación",
		fr_fr: "Invitation",
		it_it: "Invito"
	},
	'ticket_type___invite___name_in_list': {
		de_de: "Name auf der Liste",
		en_us: "Name in List",
		es_es: "Nombre en la Lista",
		fr_fr: "Nom sur la Liste",
		it_it: "Nome In Lista"
	},
	'field___name_surname': {
		de_de: "Ticket-Inhaber",
		en_us: "Ticket Holder",
		es_es: "Titular del Ticket",
		fr_fr: "Titulaire du Billet",
		it_it: "Intestazione del Ticket"
	},
	'field___name_surname___placeholder': {
		de_de: "Vorname Nachname",
		en_us: "First Name Last Name",
		es_es: "Nombre Apellido",
		fr_fr: "Prénom Nom",
		it_it: "Nome Cognome"
	},
	'field___email': {
		de_de: "E-Mail",
		en_us: "Email",
		es_es: "Correo Electrónico",
		fr_fr: "Email",
		it_it: "Email"
	},
	'field___email___placeholder': {
		de_de: "E-Mail",
		en_us: "Email",
		es_es: "Correo Electrónico",
		fr_fr: "Email",
		it_it: "Email"
	},
	'field___email___description': {
		de_de: "Geben Sie die E-Mail dieser Person ein, um ihr automatisch den QR-Code des Tickets zu senden",
		en_us: "Enter this person's email to automatically send them the ticket's QR Code",
		es_es: "Introduce el correo de esta persona para enviarle automáticamente el código QR del ticket",
		fr_fr: "Entrez l'email de cette personne pour lui envoyer automatiquement le QR Code du billet",
		it_it: "Inserisci la mail di questa persona per inviargli automaticamente il QR Code del ticket"
	},

	






	'field___notes': {
		de_de: "Ticket-Notizen",
		en_us: "Ticket Notes",
		es_es: "Notas del Ticket",
		fr_fr: "Notes du Billet",
		it_it: "Note sul Ticket"
	},
	'field___notes___placeholder': {
		de_de: "Notizen nur für das Personal sichtbar...",
		en_us: "Notes visible only to staff...",
		es_es: "Notas visibles solo para el personal...",
		fr_fr: "Notes visibles uniquement par le personnel...",
		it_it: "Note visibili solo allo staff..."
	}

	/*
		'________________': {
			de_de: "________________",
			en_us: "________________",
			es_es: "________________",
			fr_fr: "________________",
			it_it: "________________"
		},
	*/

};