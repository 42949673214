import React from 'react'
import { IonCard, IonItem, IonLabel, IonNote } from '@ionic/react';

//	L O C A L E

import { locale___ComTicketTypeComponent } from './com-ticket-type.locale';

//	S T Y L E

import './com-ticket-type.scss'

//	S T A T E S   -   P R O P S

import { props___ComTicketTypeComponent } from './com-ticket-type.state';

//	F U N C S

import { funcs_numbers } from '../../../funcs/funcs.numbers';

//	S E R V I C E S

import { service_LocalizationService } from '../../../services/service-localization';

//	C L A S S

export default class ComTicketTypeComponent extends React.Component<props___ComTicketTypeComponent, {}> {

//#region 																							D E C L A R A T I O N S

	private readonly _LOCALE: service_LocalizationService = new service_LocalizationService();

	private readonly funcs___numbers: funcs_numbers = new funcs_numbers();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___ComTicketTypeComponent,
	) {
		super(props);
	}

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode {
		return <>
			<IonCard>
				<IonItem>
					<IonLabel><b>{ this.props.ticket_type___props['ticket_type_name'] }</b></IonLabel>
					<IonNote><b>{ this.funcs___numbers.number___format_price(this.props.ticket_type___props['ticket_type_price']) }</b></IonNote>
				</IonItem>
				{(this.props.show___description !== false) ? <>
						{(this.props.ticket_type___props['ticket_type_quantity_available'] && this.props.ticket_type___props['ticket_type_quantity_available'] > 0) ? <>
							<p className="com-ticket-type---quantity">{ this._LOCALE.translate(locale___ComTicketTypeComponent.ticket___quantity_available, [this.props.ticket_type___props['ticket_type_quantity_available'], this.props.ticket_type___props['ticket_type_quantity_sold']]) }</p>
						</> : <></>}
					{(this.props.ticket_type___props['ticket_type_description'] && this.props.ticket_type___props['ticket_type_description'].length > 0) ? <>
						<p className="com-ticket-type---description">{ this.props.ticket_type___props['ticket_type_description'] }</p>
					</> : <></>}
				</> : <></>}
			</IonCard>
		</>;
	}

//#endregion

}