import { interface___modal } from "../../../interfaces/interface.modals";
import { type___ticket___type } from "../../../types/types.types";

//	P R O P S

export interface props___MdCashDeskCheckoutModal extends interface___modal {

	event_props: string,
	ticket_props: any | null,

}

//	S T A T E

export interface state___MdCashDeskCheckoutModal {

	event___props: any | null,

	ticket___props: any | null,
	ticket___props___type_props: any | null,
	ticket___props___type_props___isLoading: boolean,

	pr___props: any | null,
	pr___props___isLoading: boolean,

}

//	S T A T E   D E F A U L T

export const state___MdCashDeskCheckoutModal___default: state___MdCashDeskCheckoutModal = {

	event___props: null,
	
	ticket___props:  null,
	ticket___props___type_props: null,
	ticket___props___type_props___isLoading: true,

	pr___props: null,
	pr___props___isLoading: true,

}