//	T Y P E

import { type___locale___language } from "../types/types.locale";

//	E X P O R T

export class service_LocalizationService {

//#region 																							G E T   L A N G U A G E

	private language_get() : type___locale___language {
	
	/*	const ___language_map: { [key: string]: 'it_it' | 'en_us' | 'fr_fr' | 'de_de' | 'es_es' } = {
			'it': 'it_it', 'it-IT': 'it_it',
			'en': 'en_us', 'en-US': 'en_us',
			'fr': 'fr_fr', 'fr-FR': 'fr_fr',
			'de': 'de_de', 'de-DE': 'de_de',
			'es': 'es_es', 'es-ES': 'es_es',
		};
	
		const ___language_selected = navigator.language || navigator.languages[0] || 'en-US';
	
		return ___language_map[___language_selected] as type___locale___language || 'en_us' as type___locale___language;
*/

	//	return 'en_us';

		return 'it_it';

	}

//#endregion

//#region 																							T R A N S L A T E

	public translate(___input: any, ___params: string[] = []) : string {

		const ___language_selected: type___locale___language = this.language_get();
		let ___string___input: string = ___input[___language_selected] as string;

		for (let ___i_p = 0; ___i_p < ___params.length; ___i_p++) {
			const ___param___placeholder: string = '{{' + (___i_p + 1).toString() + '}}'; 
			const ___param___value: string = ___params[___i_p];
			___string___input = ___string___input.replace(___param___placeholder, ___param___value);
		}

		return ___string___input as string;

	}

//#endregion

}