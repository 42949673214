import React from 'react'

//	S T Y L E

import './com-event-text.scss'

//	S T A T E S   -   P R O P S

import { props___ComEventTextComponent } from './com-event-text.state';

//	C L A S S

export default class ComEventTextComponent extends React.Component<props___ComEventTextComponent, {}> {

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___ComEventTextComponent,
	) {
		super(props);
	}

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode {
		return <>
			<label className="com-event-text---component" htmlFor="com-poi-text---input">
				<input type="radio" id="com-poi-text---input" checked={(this.props.text.length < 250) ? true : false}/>
				<div>
					<p>{ this.props.text }</p>
					<div>
						<i className="fa-solid fa-chevron-down"></i>
					</div>
				</div>
			</label>
		</>;
	}

//#endregion

}