import React from 'react'
import { shareOnMobile } from "react-mobile-share";

//	L O C A L E

import { locale___ComButtonTicketShareComponent } from './com-button-ticket-share.locale';

//	L I B S

import { lib_names_shared } from '../../libs/lib.names.shared';

//	S T A T E S   -   P R O P S

import { props___ComButtonTicketShareComponent, state___ComButtonTicketShareComponent, state___ComButtonTicketShareComponent___default } from './com-button-ticket-share.state';

//	S E R V I C E S

import { service_LocalizationService } from '../../services/service-localization';

//	C L A S S

export default class ComButtonTicketShareComponent extends React.Component<props___ComButtonTicketShareComponent, state___ComButtonTicketShareComponent> {

//#region 																							C O N S T R U C T O R

	private readonly _LOCALE: service_LocalizationService = new service_LocalizationService();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___ComButtonTicketShareComponent,
	) {
		super(props);
		this.state = state___ComButtonTicketShareComponent___default;
	}

//#endregion

//#region 																							C O N S T R U C T O R

	private handle___share = async () => {
		const ___url___complete: string = lib_names_shared.endpoints.qr + this.props.ticket_code.toUpperCase();
		try {

			shareOnMobile({
				text: this._LOCALE.translate(locale___ComButtonTicketShareComponent.share_text, [this.props.ticket_event_props['event_name'], ___url___complete]),
				url: ___url___complete,
				title: this.props.ticket_event_props['event_name'],
			});

		} catch (___e) {
			
			console.log('Using Navigator   -->   ', ___e);

			if (navigator.share)
			{
				try {
					await navigator.share({
						title: this.props.ticket_event_props['event_name'],
						text: this._LOCALE.translate(locale___ComButtonTicketShareComponent.share_text, [this.props.ticket_event_props['event_name'], ___url___complete]),
						url: ___url___complete
					});
					return 'shared';
				} catch (___e_n: any) {
					console.log('Error sharing   -->   ', ___e_n);
				}
			}
	
			try {
				await navigator.clipboard.writeText(___url___complete);
				return 'copyed';
			} catch (___e_c: any) {
				console.error('Error copyng   -->   ', ___e_c);
			}

		}
	}

//#endregion

//#region 																							R E N D E R

	render(): React.ReactNode {
		return <>
			<button className="is-element is-button is-color---folly is-full-width" onClick={() => { this.handle___share(); }}>
				<i className="fa-solid fa-share"></i>
				<h4>{this._LOCALE.translate(locale___ComButtonTicketShareComponent.share_button)}</h4>
			</button>
		</>;
	}

//#endregion

}