import React from 'react'
import { IonBadge, IonItem, IonLabel } from '@ionic/react';

//	T Y P E S

import { type___ticket___type } from '../../../types/types.types';

//	S T A T E S   -   P R O P S

import { props___ComTicketComponent } from './com-ticket.state';

//	F U N C S

import { funcs_datetime } from '../../../funcs/funcs.datetime';

//	C L A S S

export default class ComTicketComponent extends React.Component<props___ComTicketComponent, {}> {

//#region 																							D E C L A R A T I O N S

	private readonly funcs___datetime: funcs_datetime = new funcs_datetime();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___ComTicketComponent,
	) {
		super(props);
	}

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode {
		return <>
			<IonItem onClick={ this.props.event___onClick } className="is-ion-item-button">

				{(() => { switch (this.props.ticket___props['ticket_type'] as type___ticket___type) {
					case null: return <><i className="fa-solid fa-ticket"></i></>; break;
					default: return <><i className="fa-solid fa-qrcode"></i></>; break;
				}})()}

				{(this.props.ticket___props['ticket_person_name'] && this.props.ticket___props['ticket_person_name'].length > 0) ? <>
					<IonLabel>{ this.props.ticket___props['ticket_person_name'] }</IonLabel>
				</> : <>
					<IonLabel style={{opacity:0.25,fontWeight:'bold',fontStyle:'italic'}}>Invito Anonimo</IonLabel>
				</>}
				
				{(this.props.ticket___props['ticket_used'] && this.props.ticket___props['ticket_used'] === true) ? <>
					<IonBadge color="success">
						{(this.props.ticket___props['ticket_used_datetime']) ? <>
							<>USATO alle </>
							<>{ this.funcs___datetime.datetime___get___time(this.props.ticket___props['ticket_used_datetime']) }</>
						</> : <>
							<>USATO</>
						</>}
					</IonBadge>
				</> : <>
					{(this.props.ticket___show_pr === true && this.props.ticket___props['ticket_pr_props']) ? <>
						<IonBadge color="light" slot="end">
							{(this.props.ticket___props['ticket_pr_props']['user_name_displayed'] !== null && this.props.ticket___props['ticket_pr_props']['user_name_displayed'] !== '') ? <>
								<span>{ this.props.ticket___props['ticket_pr_props']['user_name_displayed'] }</span>
							</> : <>
								<span>{ this.props.ticket___props['ticket_pr_props']['user_name'] + ' ' + this.props.ticket___props['ticket_pr_props']['user_surname'] }</span>
							</>}
						</IonBadge>
					</> : <></>}
					{(this.props.ticket___props['ticket_notes'] && this.props.ticket___props['ticket_notes'].length > 0) ? <>
						<IonBadge color="light" slot="end" style={{marginLeft:'5pt'}}>
							<i className="fa-solid fa-ellipsis"></i>
						</IonBadge>
					</> : <></>}
				</>}

			</IonItem>
		</>;
	}

//#endregion

}