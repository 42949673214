import React from 'react'

//	S T Y L E

import './com-list-empty.scss'

//	P R O P S

import { props___ComListEmptyComponent } from './com-list-empty.state';

//	C L A S S

export default class ComListEmptyComponent extends React.Component<props___ComListEmptyComponent, {}> {

//#region 																							R E N D E R

	render() : React.ReactNode {
		return <>
			<div className="com-list-empty---component">
				<h5>{ this.props.text ?? 'nessun risultato...' }</h5>
			</div>
		</>;
	}

//#endregion

}