import { interface___page } from "../../../interfaces/interface.page"

//	P R O P S

export interface props___SbTicketsTypesPage extends interface___page {
	
}

//	S T A T E

export interface state___SbTicketsTypesPage {

	event___id: string,
	event___props: any | null,
	event___props___isLoading: boolean,

	tickets_types___collection: any[],
	tickets_types___collection___isLoading: boolean,

	modal___MdTicketViewModal___isOpen: boolean
	modal___MdTicketViewModal___argument: string | null,
	modal___MdTicketTypeEditModal___isOpen: boolean,

}

//	S T A T E   D E F A U L T

export const state___SbTicketsTypesPage___default: state___SbTicketsTypesPage = {

	event___id: '',
	event___props: null,
	event___props___isLoading: true,

	tickets_types___collection: [],
	tickets_types___collection___isLoading: true,

	modal___MdTicketViewModal___isOpen: false,
	modal___MdTicketViewModal___argument: null,
	modal___MdTicketTypeEditModal___isOpen: false,

}