//	E X P O R T   L I B

export const lib_names_shared = {
	external: {
		server_nearby: 'https://server.nearbycommunity.it/ticket/',
	},
	endpoints: {
		qr: 'https://ticket.nopr.me/',
	},
	regex: {
		credential___username: /^[a-zA-Z0-9_]{5,12}$/,
		credential___email: /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9_.-]+\.[a-zA-Z_.-]{2,}$/i,
		credential___password: /^(.{6,16})$/,
		css_rotation: /rotate\(([^)]+)deg\)/,
		input___datetime: /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/,
		text___url: /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/,
		text___hashtag: /^#\w+/,
		text___usertag: /^@\w+/,
		text___whitespace: /\s+/,
		types___objectId: /\b[a-f0-9]{24}\b/,
	},
}