import React from 'react';
import { IonBackButton, IonButtons, IonCard, IonContent, IonHeader, IonItem, IonLabel, IonList, IonListHeader, IonNote, IonPage, IonRefresher, IonSelect, IonSelectOption, IonToolbar, RefresherEventDetail, withIonLifeCycle } from '@ionic/react';
//import { Chart as ChartJS } from 'chart.js/auto'

//	S T Y L E

import './sb-analytics-global.scss';

//	F U N C S

import { funcs_numbers } from '../../../funcs/funcs.numbers';

//	S T A T E   -   P R O P S

import { props___SbAnalyticsGlobalPage, state___SbAnalyticsGlobalPage, state___SbAnalyticsGlobalPage___default } from './sb-analytics-global.state';

//	T Y P E S

import { type___api___response } from '../../../types/types.api-response';

//	S E R V I C E S

import { service_RestApiService } from '../../../services/service-api';
import { service_Router } from '../../../services/service-router';

//	C O M P O N E N T S

import ComBannerHintComponent from '../../../components/com-banner-hint/com-banner-hint';
import ComEventComponent from '../../../components/com-events/com-event/com-event';
import ComEventTimeScheduleComponent from '../../../components/com-events/com-event-timeschedule/com-event-timeschedule';
import ComSpinnerComponent from '../../../components/com-spinners/com-spinner/com-spinner';
import ComSpinnerMiniComponent from '../../../components/com-spinners/com-spinner-mini/com-spinner-mini';

//	C L A S S

class SbAnalyticsGlobalPage extends React.Component<props___SbAnalyticsGlobalPage, state___SbAnalyticsGlobalPage> {

//#region 																							D E C L A R A T I O N S
		
	private readonly _API: service_RestApiService = new service_RestApiService();
	private readonly _ROUTER: service_Router = new service_Router();

	private readonly funcs____numbers: funcs_numbers = new funcs_numbers();

//	private ___htmlRef___chart___analytics_event = createRef<HTMLCanvasElement>();
//	private ___htmlRef___chart___analytics_event___instance?: ChartJS<'bar'>;

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___SbAnalyticsGlobalPage,
	) {
		super(props);
		this.state = state___SbAnalyticsGlobalPage___default;
	}

//#endregion

//#region 																							R E A D   P R O P S

	read_props___event = async () => {
		const ___temp___event___props: type___api___response = await this._API.events___read___single(this.state.event___id);
		if (!___temp___event___props || ___temp___event___props.response !== 'success' || ___temp___event___props.data === null) { console.log('404'); }
		this.setState({
			event___props: ___temp___event___props.data,
			event___props___is_loading: false
		}, async () => {
	//		await this.rear_props___chart___analytics_event();
			await this.rear_props___chart___user_tickets();
		});
	}

/*
	rear_props___chart___analytics_event = async () => {
		const ___a_chart___event_props: any = await this._API.analytics___read___event_graph(this.state.event___id);
		if (___a_chart___event_props.data === null) { return; }
		const ___htmlRef___chart___accounting___element = this.___htmlRef___chart___analytics_event.current?.getContext('2d');
		if (___htmlRef___chart___accounting___element) {
			this.___htmlRef___chart___analytics_event___instance = new ChartJS(___htmlRef___chart___accounting___element, {
				type: 'bar',
				data: {...___a_chart___event_props.data}, 
				options: {
					responsive: true,
					maintainAspectRatio: false,
					scales: {
						y: { beginAtZero: false, },
					},
					backgroundColor: 'black'
				},
			});
		}
		this.setState({
			event_analytics___chart___props: ___a_chart___event_props.data,
			event_analytics___chart___is_loading: false,
		});
	}
*/

	rear_props___chart___user_tickets = async () => {
		const ___a_chart___event_props: any = await this._API.analytics___read___users(this.state.event___id, this.state.event_users___props___order, false);
		if (___a_chart___event_props.data === null) { return; }
		this.setState({
			event_users___props: ___a_chart___event_props.data,
			event_users___props___is_loading: false,
		});
	}
	
//#endregion

//#region 																							H A N D L E R S

	handle___pageRefresh = async (___e: CustomEvent<RefresherEventDetail>) => {
		await this.read_props___event();
		___e.detail.complete();
	}

	handle___users___sort = (___event: any) => {
		this.setState({
			event_users___props: [],
			event_users___props___order: ___event.detail.value,
			event_users___props___is_loading: true,
		}, async () => {
			await this.rear_props___chart___user_tickets();
		});
	}

//#endregion

//#region 																							L I F E C Y C L E

	ionViewDidEnter() : void {
		const ___event___id: string | null = this._ROUTER.get_id(this.props);
		this.setState({
			event___id: ___event___id!,
		}, async () => {
			await this.read_props___event();
		});
	}

//#endregion

//#region 																							R E T U R N

	render() : React.ReactNode {
		return <>

			<IonPage>

				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonBackButton defaultHref="/dashboard"></IonBackButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>

				<IonContent>

					<IonRefresher slot="fixed" onIonRefresh={ this.handle___pageRefresh }>
						<ComSpinnerMiniComponent/>
					</IonRefresher>

					{(this.state.event___props && this.state.event___props['event_is_now'] === true) ? <>
						<section style={{padding:'10pt'}}>
							<ComBannerHintComponent text="Attenzione, l'evento è in corso e le statistiche potrebbero non essere accurate. Per le statistiche accurate attendi la fine dell'evento"/>
						</section>
					</> : <></>}

					<IonListHeader>
						<IonLabel>Panoramica Evento</IonLabel>
					</IonListHeader>

					<section className="sb-analytics-event---event">
						{(this.state.event___props___is_loading === true) ? <>
							<ComSpinnerComponent size="small"/>
						</> : <>
							<ComEventTimeScheduleComponent event___props={ this.state.event___props } />
							<br />
							<IonCard>
								<ComEventComponent event___props={ this.state.event___props }/>
							</IonCard>
						</>}
					</section>

					<br />

					<IonListHeader>
						<IonLabel>Panoramica Globale</IonLabel>
					</IonListHeader>

					<br />

					<section className="sb-analytics-event---event-resume">
						<IonCard>
							{(this.state.event_users___props___is_loading === false) ? <>
								<IonList>
									{(this.state.event___props['event_settings___tickets_standards'] === true) ? <>
										<IonItem className="is-ion-item-button">
											<i className="fa-solid fa-qrcode"></i>
											<IonLabel>Tickets timbrati</IonLabel>
											<IonNote>
												<b>{ this.funcs____numbers.number___number_or_symbol(this.state.event_users___props['tickets___total_standards_used'], 'nessuno') }</b>
												<span> di </span>
												<b>{ this.funcs____numbers.number___number_or_symbol(this.state.event_users___props['tickets___total_standards'], 'nessuno') }</b>
											</IonNote>
										</IonItem>
									</> : <>
										<IonItem className="is-ion-item-button" style={{opacity:0.5}}>
											<i className="fa-solid fa-qrcode"></i>
											<IonLabel>Tickets non abilitati</IonLabel>
										</IonItem>
									</>}
									{(this.state.event___props['event_settings___tickets_lists'] === true) ? <>
										<IonItem className="is-ion-item-button">
											<i className="fa-solid fa-calendar-week"></i>
											<IonLabel>Inviti utilizzati</IonLabel>
											<IonNote>
												<b>{ this.funcs____numbers.number___number_or_symbol(this.state.event_users___props['tickets___total_invites_used'], 'nessuno') }</b>
												<span> di </span>
												<b>{ this.funcs____numbers.number___number_or_symbol(this.state.event_users___props['tickets___total_invites'], '-') }</b>
											</IonNote>
										</IonItem>
									</> : <>
										<IonItem className="is-ion-item-button" style={{opacity:0.5}}>
											<i className="fa-solid fa-calendar-week"></i>
											<IonLabel>Inviti non abilitati</IonLabel>
										</IonItem>
									</>}
									{/*
										{(this.state.event___props['event_settings___tickets_online'] === true) ? <>
											<IonItem className="is-ion-item-button">
												<i className="fa-solid fa-globe"></i>
												<IonLabel>Online</IonLabel>
											</IonItem>
										</> : <></>}
									*/}
								</IonList>
							</> : <>
								<br />
								<br />
								<ComSpinnerComponent size="small"/>
								<br />
								<br />
							</>}
						</IonCard>
					</section>

					<br />

					<IonListHeader>
						<IonLabel>Rendimento PR</IonLabel>
					</IonListHeader>

					<section className="sb-tickets-list---options-selector">
						<IonSelect key="sort___" interface="popover" label="Ordina per" placeholder="Standard" onIonChange={ this.rear_props___chart___user_tickets }>
							<IonSelectOption key="sort___standard" value="standard">Standard</IonSelectOption>
							<IonSelectOption key="sort___performance" value="performance">Rendimento</IonSelectOption>
							<IonSelectOption key="sort___name_asc" value="name_asc">Nome (A-Z)</IonSelectOption>
							<IonSelectOption key="sort___name_desc" value="name_desc">Nome (Z-A)</IonSelectOption>
						</IonSelect>
					</section>
					{(this.state.event_users___props___is_loading === false) ? <>
						<IonList className="sb-analytics-event---pr-list">
							<IonItem className="is-ion-item-button" key={ 0 }>
								<IonLabel></IonLabel>
								{(this.state.event___props['event_settings___tickets_standards'] === true) ? <>
									<IonNote><i className="fa-solid fa-qrcode"></i></IonNote>
								</> : <></>}
								{(this.state.event___props['event_settings___tickets_lists'] === true) ? <>
									<IonNote><i className="fa-solid fa-calendar-week"></i></IonNote>
								</> : <></>}
							</IonItem>
							{this.state.event_users___props['tickets___by_pr'].map((___tas: any, ___tap___idx: number) => {
								return <>
									<IonItem className="is-ion-item-button" key={ ___tap___idx }>
										<IonLabel>
											<>{ ___tas['user_name'] + ' ' + ___tas['user_surname'] }</>
											{(___tas['user_name_displayed'] && ___tas['user_name_displayed'].length > 0) ? <>
												<span style={{opacity:0.5,fontWeight:'bold'}}> - { ___tas['user_name_displayed'] }</span>
											</> : <></>}
										</IonLabel>
										{(this.state.event___props['event_settings___tickets_standards'] === true) ? <>
											<IonNote>
												{(this.state.event___props['event_is_now'] === true || this.state.event___props['event_ended']) ? <>
													<b>{ this.funcs____numbers.number___number_or_symbol(___tas['user_tickets']['standards_used']) }</b>
													<span>/</span>
												</> : <></>}
												<b>{ this.funcs____numbers.number___number_or_symbol(___tas['user_tickets']['standards_total']) }</b>
											</IonNote>
										</> : <></>}
										{(this.state.event___props['event_settings___tickets_lists'] === true) ? <>
											<IonNote>
												{(this.state.event___props['event_is_now'] === true || this.state.event___props['event_ended']) ? <>
													<b>{ this.funcs____numbers.number___number_or_symbol(___tas['user_tickets']['invites_used']) }</b>
													<span>/</span>
												</> : <></>}
												<b>{ this.funcs____numbers.number___number_or_symbol(___tas['user_tickets']['invites_total']) }</b>
											</IonNote>
										</> : <></>}
									</IonItem>
								</>;
							})}
						</IonList>
					</> : <>
						<br />
						<br />
						<ComSpinnerComponent size="small"/>
						<br />
						<br />
					</>}

					<br />

					{/**

						<IonListHeader>
							<IonLabel>Panoramica Orari Entrate</IonLabel>
						</IonListHeader>
						
						<br />

						<IonListHeader>
							<IonLabel>Panoramica Orari Entrate</IonLabel>
						</IonListHeader>
						
						<br />

						{(this.state.event___props !== null) ? <>
							<div className="sb-page---container sb-analytics-event---container">
								{(this.state.event_analytics___chart___is_loading === false) ? <>
									<canvas ref={ this.___htmlRef___chart___analytics_event } />
								</> : <></>}
							</div>
						</> : <>
							<br />
							<br />
							<br />
							<br />
							<br />
							<br />
							<ComSpinnerComponent size="small"/>
						</>}

					*/}

					<br />
					<br />
					<br />

				</IonContent>

			</IonPage>

		</>;
	}

//#endregion

}

//	E X P O R T

export default withIonLifeCycle(SbAnalyticsGlobalPage);