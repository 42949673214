import { interface___page } from "../../interfaces/interface.page";

//	P R O P S

export interface props___SbDiscoverFeedPage extends interface___page {
	
}

//	S T A T E

export interface state___SbDiscoverFeedPage {

	event___props: any | null,
	event___props___isLoading: boolean,

	modal___MdShareModal___isOpen: boolean,
	modal___MdShareModal___argument: string | null,
	modal___MdTicketEditModal___isOpen: boolean,
	modal___MdTicketViewModal___isOpen: boolean,
	modal___MdTicketViewModal___argument: string | null,

}

//	S T A T E   D E F A U L T

export const state___SbDiscoverFeedPage___default: state___SbDiscoverFeedPage = {

	event___props: null,
	event___props___isLoading: true,

	modal___MdShareModal___isOpen: false,
	modal___MdShareModal___argument: null,
	modal___MdTicketEditModal___isOpen: false,
	modal___MdTicketViewModal___isOpen: false,
	modal___MdTicketViewModal___argument: null,

}