import React from 'react';
import { marked } from 'marked';

//	S T Y L E

import './com-markdown.scss'

//	T Y P E S

import { props___ComMarkdownComponent, state___ComMarkdownComponent, state___ComMarkdownComponent___defaults } from './com-markdown.state';

//	E X P O R T

export default class ComMarkdownComponent extends React.Component<props___ComMarkdownComponent, state___ComMarkdownComponent> {

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___ComMarkdownComponent,
	) {
		super(props);
		this.state = state___ComMarkdownComponent___defaults;
	}

//#endregion

//#region 																							L I F E C Y C L E

	componentDidMount(): void {
		fetch(this.props.file_path)
			.then((___r: any) => ___r.text())
			.then((___t: any) => this.setState({ markdown_content: marked(___t) as string }));
	}

//#endregion

//#region 																							R E T U R N

	render(): React.ReactNode {
		return <>
			<div className="md-syled---container">
				<br />
				<div dangerouslySetInnerHTML={{ __html: this.state.markdown_content }} />
				<br />
			</div>
		</>;
	}

//#endregion

}