import { interface___page } from "../../interfaces/interface.page";

//	P R O P S

export interface props___SbCashdeskPage extends interface___page {
	
}

//	S T A T E

export interface state___SbCashdeskPage {

	cashdesk___available: boolean | null,

	event___props: any | null,
	event___props___isLoading: boolean,

	section___selected: 'scanner' | 'list',
	section___selected___list_view: 'all' | 'unused',

	ticket___found: null | true | false,
	ticket___found___object: any | null,

	tickets___collection: any[],
	tickets___collection___isLoading: boolean,

}

//	S T A T E   D E F A U L T

export const state___SbCashdeskPage___default: state___SbCashdeskPage = {

	cashdesk___available: null,

	event___props: null,
	event___props___isLoading: true,

	section___selected: 'scanner',
	section___selected___list_view: 'unused',

	ticket___found: false,
	ticket___found___object: null,

	tickets___collection: [],
	tickets___collection___isLoading: true,

}