import React from 'react'
import { IonCard } from '@ionic/react';

//	S T Y L E

import './com-banner-hint.scss'

//	S T A T E S   -   P R O P S

import { props___ComBannerHintComponent } from './com-banner-hint.state';

//	C L A S S

export default class ComBannerHintComponent extends React.Component<props___ComBannerHintComponent, {}> {

//#region 																							R E N D E R

	render() : React.ReactNode {
		return <>
			<IonCard className="com-banner-hint---container" color="primary">
				<p>{ this.props.text }</p>
			</IonCard>
		</>;
	}

//#endregion

}