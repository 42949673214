export const locale___SbDashboardPage = {
	'button___event_change': {
		de_de: "Event ändern",
		en_us: "Change event",
		es_es: "Cambiar evento",
		fr_fr: "Changer d'événement",
		it_it: "cambia evento"
	},
	'nav___cashdesk': {
		de_de: "Kasse",
		en_us: "CashDesk",
		es_es: "Caja",
		fr_fr: "Caisse",
		it_it: "CashDesk"
	},
	'nav___add___list': {
		de_de: "Einladung zur Liste hinzufügen",
		en_us: "Add Invitation to List",
		es_es: "Agregar Invitación a la Lista",
		fr_fr: "Ajouter une invitation à la liste",
		it_it: "Aggiungi Invito in Lista"
	},
	'nav___ticket': {
		de_de: "Online-Ticket teilen",
		en_us: "Share for Online Ticket",
		es_es: "Compartir para Ticket Online",
		fr_fr: "Partager pour Billet en Ligne",
		it_it: "Condividi per Ticket Online"
	},
	'nav___ticket_list': {
		de_de: "Ticket-Liste",
		en_us: "Ticket List",
		es_es: "Lista de Entradas",
		fr_fr: "Liste des Billets",
		it_it: "Lista Tickets"
	},
	'nav___ticket_settings': {
		de_de: "Ticket-Einstellungen",
		en_us: "Ticket Settings",
		es_es: "Configuración de Entradas",
		fr_fr: "Paramètres des Billets",
		it_it: "Impostazioni Tickets"
	},
	'nav___stats___event': {
		de_de: "Event-Statistiken",
		en_us: "Event Statistics",
		es_es: "Estadísticas del Evento",
		fr_fr: "Statistiques de l'Événement",
		it_it: "Statistiche Evento"
	},
	'nav___stats___personal': {
		de_de: "Persönliche Statistiken",
		en_us: "Personal Statistics",
		es_es: "Estadísticas Personales",
		fr_fr: "Statistiques Personnelles",
		it_it: "Statistiche Personali"
	},
	'nav___event_list': {
		de_de: "Event-Liste",
		en_us: "Event List",
		es_es: "Lista de Eventos",
		fr_fr: "Liste des Événements",
		it_it: "Lista Eventi"
	},
	'nav___staff': {
		de_de: "Veranstaltungs-Team",
		en_us: "Event Staff",
		es_es: "Personal de Organización",
		fr_fr: "Équipe d'Organisation",
		it_it: "Staff Organizzazione"
	},
	'nav___settings': {
		de_de: "Einstellungen",
		en_us: "Settings",
		es_es: "Configuraciones",
		fr_fr: "Paramètres",
		it_it: "Impostazioni"
	}
};