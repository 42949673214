import React from 'react';
import { IonBackButton, IonButtons, IonContent, IonFooter, IonHeader, IonPage, IonToolbar, RefresherEventDetail, withIonLifeCycle } from '@ionic/react';

//	S T Y L E

import './sb-settings-profile.scss';

//	L I B S

import { lib_names } from '../../../libs/lib.names';

//	S T A T E   -   P R O P S

import { state___SbSettingsProfilePage, state___SbSettingsProfilePage___default } from './sb-settings-profile.state';

//	I N T E R F A C E S

//	T Y P E S

import { type___api___response } from '../../../types/types.api-response';

//	S E R V I C E S

import { service_RestApiService } from '../../../services/service-api';

//	C O M P O N E N T S

import ComSpinnerComponent from '../../../components/com-spinners/com-spinner/com-spinner';

//	C L A S S

class SbSettingsProfilePage extends React.Component<{}, state___SbSettingsProfilePage>
{

//#region 																							D E C L A R A T I O N S
		
	private readonly _API: service_RestApiService = new service_RestApiService();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: {},
	)
	{
		super(props);
		this.state = state___SbSettingsProfilePage___default;
	}

//#endregion

//#region 																							R E A D   P R O P S

	read_props___user = async () => {
		const ___temp___user___props: type___api___response = await this._API.users___read___single('me', null);
		if (!___temp___user___props || ___temp___user___props.response !== 'success' || ___temp___user___props.data === null) { console.log('404'); }
		this.setState({
			user___props: ___temp___user___props.data,
			user___props___is_loading: false,
		}, () => {
			this.setState({

				field___user___name___displayed: this.state.user___props['user_name_displayed'],
				field___user___name: this.state.user___props['user_name'],
				field___user___surname: this.state.user___props['user_surname'],

				field___user___contact_email: this.state.user___props['user_contact_mail'],
				field___user___contact_phone: this.state.user___props['user_contact_phone'],

				user___props___is_loading: false,

			});
		});
	}

//#endregion

//#region 																							H A N D L E R S

	handle___pageRefresh = async (___e: CustomEvent<RefresherEventDetail>) => {
		await this.read_props___user();
		___e.detail.complete();
	}

//#endregion

//#region 																							S U B M I T

	submit___new_user = async () => {
		
		








	}

//#endregion

//#region 																							L I F E C Y C L E

	async ionViewWillEnter() : Promise<void>
	{
		await this.read_props___user();
	}

//#endregion

//#region 																							R E T U R N

	render() : React.ReactNode
	{

		return <>
			<IonPage>

				<IonHeader className="ion-no-border" collapse="fade">
					<IonToolbar>
						<IonButtons slot="start">
							<IonBackButton defaultHref="/settings"></IonBackButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>

				<IonContent>

					{(this.state.user___props___is_loading === false) ? <>

						<div className="sb-page---container sb-settings-profile---container">

							<h2>Il tuo Profilo</h2>

							<br />

							<div className="sb-settings-profile---user-image">
								<img src={ lib_names.endpoints.avatars + this.state.user___props['user_username'] } alt="avatar"/>
							</div>

							<br />

							<h5>{ this.state.user___props['user_username'] }</h5>
			
							{(() => {
								switch (this.state.profile___is_editing) {
									case true: return <>

										<br />

										<label htmlFor="field---user-name">
											<p className="input---nb-label">nome visualizzato</p>
											<input type="text" id="field---user-name" className="input---nb-outlined" placeholder="Nome visualizzato" value={ this.state.field___user___name___displayed } onInput={(___e: any) => { this.setState({ field___user___name___displayed: ___e.target.value}); }}/>
										</label>

										<br />

										<label htmlFor="field---user-name">
											<p className="input---nb-label is-required">nome e cognome</p>
											<input type="text" id="field---user-name" className="input---nb-outlined" placeholder="Nome" value={ this.state.field___user___name } onInput={(___e: any) => { this.setState({ field___user___name: ___e.target.value}); }}/>
										</label>
										<label htmlFor="field---user-surname" style={{display:'block',paddingTop:'10pt'}}>
											<input type="text" id="field---user-surname" className="input---nb-outlined" placeholder="Cognome" value={ this.state.field___user___surname } onInput={(___e: any) => { this.setState({ field___user___surname: ___e.target.value}); }}/>
										</label>

										<br />

										<label htmlFor="field---user-name">
											<p className="input---nb-label is-required">contatti</p>
											<input type="text" id="field---user-email" className="input---nb-outlined" placeholder="email@email.com" value={ this.state.field___user___contact_email } onInput={(___e: any) => { this.setState({ field___user___contact_email: ___e.target.value}); }}/>
										</label>
										<label htmlFor="field---user-surname" style={{display:'block',paddingTop:'10pt'}}>
											<input type="text" id="field---user-phone" className="input---nb-outlined" placeholder="+39 333 333 3334" value={ this.state.field___user___contact_phone } onInput={(___e: any) => { this.setState({ field___user___contact_phone: ___e.target.value}); }}/>
										</label>

									</>; break;
									case false:
									default: return <>
										
										<br />

										<h3>
											{ this.state.field___user___name + ' ' + this.state.field___user___surname }
											{(this.state.field___user___name___displayed) ? <>
												<br />
												<span>come "</span>
												{ this.state.field___user___name___displayed }
												<span>"</span>
											</> : <></>}
										</h3>

										<br />

										{(this.state.field___user___contact_email) ? <>
											<h6>{ this.state.field___user___contact_email }</h6>
										</> : <></>}
										{(this.state.field___user___contact_phone) ? <>
											<h6>{ this.state.field___user___contact_phone }</h6>
										</> : <></>}

										<br />
										<br />

									</>; break;
								}
							})()}

						</div>
					
					</> : <>

						<br />
						<br />
						<br />
						<br />
						<br />
						<br />

						<ComSpinnerComponent size="small"/>

					</>}

				</IonContent>

				{(this.state.profile___is_editing === true) ? <>
					<IonFooter>
						<section className="container---footer-section">
							<button className="is-element is-button is-color---folly is-full-width" onClick={ this.submit___new_user }>
								<i className="fa-solid fa-check"></i>
								<h6>Salva</h6>
							</button>
						</section>
					</IonFooter>
				</> : <></>}

			</IonPage>
		</>;

	}

//#endregion

}

//	E X P O R T

export default withIonLifeCycle(SbSettingsProfilePage);