//	S T A T E

export interface state___App {

	me___isLogged: boolean | null,

	me___props: any | null,
	me___props___isLoading: boolean,

	selected_event___props: any | null,
	selected_event___props___isLoading: boolean,

	modal___password_change___visible: boolean,

}

//	S T A T E   D E F A U L T S

export const state___App___default: state___App = {

	me___isLogged: null,

	me___props: null,
	me___props___isLoading: true,

	selected_event___props: null,
	selected_event___props___isLoading: true,

	modal___password_change___visible: false,

}